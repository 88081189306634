import React, { useEffect, useState } from "react";
import { useNavigation, Modal } from "react-uicomp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import { Button, Paper } from "@material-ui/core";

import { getVacancyAction, deleteVacancyAction } from "../../actions/Actions";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

const VacancyListPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { getVacancyAction, vacancy, deleteVacancyAction } = props;
  const { vacancyList, deleteLoader } = vacancy;
  const [visible, setVisible] = useState(false);
  const [activeId, setActiveId] = useState();

  useEffect(() => {
    getVacancyAction();
  }, []);

  const deleteModal = (id) => {
    setVisible(true);
    setActiveId(id);
  };

  const onDeleteHandler = () => {
    deleteVacancyAction(activeId, closeModal);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <div>
      <Modal visible={visible} onOutsideClick={() => setVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          Are you sure you want to delete?
          <ActivityIndicator animating={deleteLoader}>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="default"
                style={{ background: "red", color: "white" }}
                onClick={() => onDeleteHandler()}
              >
                {" "}
                Delete{" "}
              </Button>
              <Button color="secondary" onClick={() => setVisible(false)}>
                Cancel
              </Button>
            </div>
          </ActivityIndicator>
        </div>
      </Modal>
      <MaterialTable
        columns={[
          {
            title: "S.N.",
            field: "tableData.id",
            render: (rowData) => rowData.tableData.id + 1,
            width: 80,
          },

          { title: "Title", field: "title" },
          {
            title: "Description",
            field: "description",
            render: (rowData) =>
              rowData.description &&
              rowData.description.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100),
          },
        ]}
        data={vacancyList}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        actions={[
          (rowData) => ({
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              navigate(routes.Vacancy.path + "/edit/" + rowData.id);
            },
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              deleteModal(rowData.id);
            },
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          toolbar: false,
          search: false,
          headerStyle: {
            fontWeight: "bold",
            fontSize: 16,
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVacancyAction,
      deleteVacancyAction,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(VacancyListPage);
