import {
  GET_LEADER,
  ADD_LEADER,
  GET_LEADER_DETAIL,
  EDIT_LEADER,
  DELETE_LEADER,
} from "./ActionTypes.action";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { toast } from "react-toastify";

export function getLeaderAction(value) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_LEADER.LOADING });
      res = await api(`${APIS.leaders}/all?type=${value}`);
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_LEADER.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_LEADER.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_LEADER.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addLeaderAction(body, formdata, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_LEADER.LOADING });

      res = await api(`${APIS.leaders}`, "POST", body);

      const { success, data } = res;

      if (success) {
        let response = await api(
          `${APIS.leaders}/${data.data.id}/addimage`,
          "PATCH",
          formdata,
          "formdata"
        );
        if (response.success === "true") {
          toast.success("Leader Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          dispatch({ type: ADD_LEADER.SUCCESS, payload: data });
          handleRedirection();
        } else {
          dispatch({ type: ADD_LEADER.ERROR });
          toast.error("Leader Adding Failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      } else {
        dispatch({ type: ADD_LEADER.ERROR });
        toast.error("Leader Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_LEADER.ERROR });
      toast.error("Leader Adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getLeaderDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_LEADER_DETAIL.LOADING });
      res = await api(`${APIS.leaders}/${id}`);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_LEADER_DETAIL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_LEADER_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_LEADER_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editLeaderAction(id, formData, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_LEADER.LOADING });

      res = await api(`${APIS.leaders}/${id}`, "PATCH", formData, "formdata");

      const { success, data } = res;

      if (success) {
        toast.success("Leader Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_LEADER.SUCCESS, payload: data });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_LEADER.ERROR });
        toast.error("Leader Editing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_LEADER.ERROR });
      toast.error("Leader Editing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteLeaderAction(id, closeModal, type) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_LEADER.LOADING });
      res = await api(`${APIS.leaders}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Leader Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_LEADER.SUCCESS, payload: data });
        closeModal();
        dispatch(getLeaderAction(type));
      } else {
        dispatch({ type: DELETE_LEADER.ERROR });
        toast.error("Leader Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_LEADER.ERROR });
      toast.error("Leader Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
