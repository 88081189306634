import { ADD_GALLARY, GET_GALLARY, GET_GALLARY_DETAIL, EDIT_GALLARY, DELETE_GALLARY } from "../actions/Actions";

const initalState = {
  getGallaryLoader: false,
  addGallaryLoader: false,
  getGallaryDetailLoader:false,
  editGallaryLoader: false,
  deleteGallaryLoader: false,
  gallaryList: [],
  gallaryDetail:null
};

export function gallaryReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GALLARY.LOADING:
      return { ...state, getGallaryLoader: true };
    case GET_GALLARY.SUCCESS:
      return { ...state, getGallaryLoader: false, gallaryList: payload };
    case GET_GALLARY.ERROR:
      return { ...state, getGallaryLoader: false };

    case ADD_GALLARY.LOADING:
      return { ...state, addGallaryLoader: true, addGallarySuccess: false };
    case ADD_GALLARY.SUCCESS:
      return { ...state, addGallaryLoader: false, addGallarySuccess: true };
    case ADD_GALLARY.ERROR:
      return { ...state, addGallaryLoader: false, addGallarySuccess: false };

    case GET_GALLARY_DETAIL.LOADING:
      return { ...state, getGallaryDetailLoader: true };
    case GET_GALLARY_DETAIL.SUCCESS:
      return { ...state, getGallaryDetailLoader: false ,gallaryDetail:payload};
    case GET_GALLARY_DETAIL.ERROR:
      return { ...state, getGallaryDetailLoader: false };

    case EDIT_GALLARY.LOADING:
      return { ...state, editGallaryLoader: true };
    case EDIT_GALLARY.SUCCESS:
      return { ...state, editGallaryLoader: false };
    case EDIT_GALLARY.ERROR:
      return { ...state, editGallaryLoader: false };

    case DELETE_GALLARY.LOADING:
      return { ...state, deleteGallaryLoader: true };
    case DELETE_GALLARY.SUCCESS:
      return { ...state, deleteGallaryLoader: false };
    case DELETE_GALLARY.ERROR:
      return { ...state, deleteGallaryLoader: false };

      default:
      return state;
  }
}
