import {
    LOGIN  } from "../actions/Actions";
      
      const initalState = {
        loginLoader: false,
      };
      
      export function loginReducer(state = initalState, action) {
        const { type,payload } = action;
      
        switch(type) {
          case LOGIN.LOADING:
            return {...state, loginLoader: true};
          case LOGIN.SUCCESS:
            return {...state, loginLoader: false};
          case LOGIN.ERROR:
            return {...state, loginLoader: false};
      
          default:
            return state;
        }
      }