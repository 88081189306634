import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import ImageUploading from "react-images-uploading";
import { TextField, Button, MenuItem } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addLeaderAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

const TYPES = [
  {
    value: "researcher",
    label: "Researcher",
  },
  {
    value: "grantee",
    label: "Grantee",
  },
  {
    value: "leader",
    label: "Leader",
  },
];

const AddLeaderPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const { addLeaderAction, leader } = props;
  const { addLeaderLoader } = leader;
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);

  const [types, setTypes] = useState("");

  const handleChange = (event) => {
    setTypes(event.target.value);
  };

  const onSubmit = (data) => {
    if (types.length === 0) return;

    let formData = new FormData();
    formData.append("images", image);
    let body = {
      name: data.name,
      position: data.position,
      bio: data.bio,
      email: data.email,
      linkedin: data.linkedin,
      type: types,
    };

    if (image) {
      addLeaderAction(body, formData, handleRedirection);
    } else {
      setImageError(true);
    }
  };

  const handleRedirection = () => {
    navigate(routes["Leaders"].path);
  };

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  return (
    <CompWrapper>
      <Header title="Add Leader" />
      <div className="addblog-container">
        <div className="addblog">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
          >
            <TextField
              name="name"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Name"
            />
            <TextField
              name="position"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Position"
            />

            <TextField
              name="bio"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Bio"
              multiline
              rows={4}
            />
            <TextField
              name="email"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Email"
            />
            <TextField
              name="linkedin"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Linked In"
            />
            <TextField
              id="standard-select-types"
              select
              label="Select Types"
              value={types}
              onChange={handleChange}
              variant="outlined"
            >
              {TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <ImageUploading multiple={false} onChange={onChangeImage}>
              {({ imageList, onImageUpload }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      marginTop: 10,
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}
                  >
                    {imageList.map((image) => (
                      <div key={image.key}>
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={onImageUpload}
                    variant="contained"
                    color="secondary"
                  >
                    Upload Image
                  </Button>
                </div>
              )}
            </ImageUploading>

            {imageError && (
              <div style={{ color: "red" }}>
                <strong>Image is Required!!</strong>
              </div>
            )}
            <ActivityIndicator animating={addLeaderLoader}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

// export default AddLeaderPage;
const mapStateToProps = (state) => {
  return {
    leader: state.leader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addLeaderAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLeaderPage);
