import React ,{useState,useEffect }from "react";
import { useNavigation, Modal } from "react-uicomp"
import {Button,Paper} from "@material-ui/core"
import MaterialTable from "material-table"

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTestimonialAction,deleteTestimonialAction } from "../../actions/Actions";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";

const TestimonialPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { getTestimonialAction, testimonial, deleteTestimonialAction } = props;
  const { testimonialList, getTestimonialLoader, deleteTestimonialLoader } = testimonial;
  const [visible, setVisible] = useState(false);
  const [leaderData, setLeaderData] = useState();

  const toAddPage = () => {
    navigate(routes.AddTestimonial.path);
  };


  useEffect(() => {
    getTestimonialAction();
  }, [getTestimonialAction]);

  const deleteModal =(id)=>{
    setVisible(true)
    setLeaderData(id)
  }
  
  const onDeleteHandler =()=>{
    deleteTestimonialAction(leaderData,closeModal)
  }
  
  const closeModal =()=>{
    setVisible(false)
  }


  return (
    <CompWrapper>
      <Header title="Testimonials"/>
      <div>

        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => toAddPage()}
        >
          add Testimonial
        </Button>

        <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{display:'flex',flexDirection:'column'}}>
                Are you sure you want to delete?
                  <ActivityIndicator animating={deleteTestimonialLoader}>
                <div style={{ marginTop: 20,    display: "flex",    justifyContent: "flex-end"}}>

                <Button color="default" style={{background:'red',color:'white'}}  onClick={()=>onDeleteHandler()}> Delete </Button>
                <Button color="secondary" onClick={() => setVisible(false)} >Cancel</Button>
                </div>
                  </ActivityIndicator>
          </div>                
      </Modal>
        <MaterialTable

          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 80,
            },
            {
              title: "Image",
              field: "images",
              render: (rowData) => (
                <img
                  alt="MediaImage"
                  style={{ height: 100, borderRadius: 4 }}
                  src={rowData.images && rowData.images[0]}
                />
              ),
            },
            { title: "Name", field: "name" },
            {
              title: "Position",
              field: "position"
            },
          ]}
          data={testimonialList?.data}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                navigate(routes.Testimonial.path+"/edit/"+rowData.id)
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id)
              },
            }),
          ]}

          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            toolbar:false,
            search: false,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
        />

      </div>
    </CompWrapper>
  )
}

// export default OurLeadersPage;
const mapStateToProps = (state) => {
  return {
    testimonial: state.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTestimonialAction,deleteTestimonialAction
    },
    dispatch
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(TestimonialPage);
