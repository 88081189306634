import {
  GET_TESTIMONIAL,
  ADD_TESTIMONIAL,
  GET_TESTIMONIAL_DETAIL,
  EDIT_TESTIMONIAL,
  DELETE_TESTIMONIAL,
} from "./ActionTypes.action";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { toast } from "react-toastify";

export function getTestimonialAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_TESTIMONIAL.LOADING });
      res = await api(`${APIS.testimonials}/all`);
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_TESTIMONIAL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_TESTIMONIAL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_TESTIMONIAL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addTestimonialAction(body, formdata, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_TESTIMONIAL.LOADING });

      res = await api(`${APIS.testimonials}`, "POST", body);

      const { success, data } = res;

      if (success) {
        let response = await api(
          `${APIS.testimonials}/${data.data.id}/addimage`,
          "PATCH",
          formdata,
          "formdata"
        );
        if (response.success === "true") {
          toast.success("Testimonial Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          dispatch({ type: ADD_TESTIMONIAL.SUCCESS, payload: data });
          handleRedirection();
        } else {
          dispatch({ type: ADD_TESTIMONIAL.ERROR });
          toast.error("Testimonial Adding Failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      } else {
        dispatch({ type: ADD_TESTIMONIAL.ERROR });
        toast.error("Testimonial Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_TESTIMONIAL.ERROR });
      toast.error("Testimonial Adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getTestimonialDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_TESTIMONIAL_DETAIL.LOADING });
      res = await api(`${APIS.testimonials}/${id}`);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_TESTIMONIAL_DETAIL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_TESTIMONIAL_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_TESTIMONIAL_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editTestimonialAction(id, formData, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_TESTIMONIAL.LOADING });

      res = await api(
        `${APIS.testimonials}/${id}`,
        "PATCH",
        formData,
        "formdata"
      );

      const { success, data } = res;

      if (success) {
        toast.success("Testimonial Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_TESTIMONIAL.SUCCESS, payload: data });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_TESTIMONIAL.ERROR });
        toast.error("Testimonial Editing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_TESTIMONIAL.ERROR });
      toast.error("Testimonial Editing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteTestimonialAction(id, closeModal) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_TESTIMONIAL.LOADING });
      res = await api(`${APIS.testimonials}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Testimonial Delete Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_TESTIMONIAL.SUCCESS, payload: data });
        closeModal();
        dispatch(getTestimonialAction());
      } else {
        dispatch({ type: DELETE_TESTIMONIAL.ERROR });
        toast.error("Testimonial Deleteing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_TESTIMONIAL.ERROR });
      toast.error("Testimonial Deleteing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
