import {
  GET_CONTACT_US,
  ADD_CONTACT_US,
  GET_CONTACT_US_DETAIL,
  EDIT_CONTACT_US,
  DELETE_CONTACT_US,
} from "./ActionTypes.action";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { toast } from "react-toastify";

export function getContactAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_CONTACT_US.LOADING });
      res = await api(`${APIS.contact}/all`);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_CONTACT_US.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_CONTACT_US.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_CONTACT_US.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function deleteContactAction(id, closeModal) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_CONTACT_US.LOADING });
      res = await api(`${APIS.contact}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Contact deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_CONTACT_US.SUCCESS, payload: data });
        closeModal();
        toast.error("Contact Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch(getContactAction());
      } else {
        dispatch({ type: DELETE_CONTACT_US.ERROR });
        toast.error("Contact Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_CONTACT_US.ERROR });
      toast.error("Contact Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
