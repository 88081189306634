import React,{useState} from 'react';
import MaterialTable from "material-table";
import {api } from "../../helpers/Api.helper";
import {APIS } from "../../config/Api.config";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";

const AddUserPage = () => {
    const [columns, setColumns] = useState([
        { title: 'Name', field: 'email' },
        { title: 'Password', field: 'password' },
    ]);

    return ( 
        <CompWrapper>
        <Header title="Add user"/>
        <div className="adduser-container">
            <div className="adduser">
  
hello add user page

            </div>
        </div>
        </CompWrapper>
     );
}
 
export default AddUserPage;