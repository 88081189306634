import React, { useState, useEffect } from "react";
import { useNavigation, Modal } from "react-uicomp";
import { Button, Paper } from "@material-ui/core";
import MaterialTable from "material-table";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getContactAction,
  deleteContactAction,
  getC,
} from "../../actions/Actions";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";

const ContactUsPage = (props) => {
  const { getContactAction, contact, deleteContactAction } = props;
  const { contactList, getContactLoader, deleteContactLoader } = contact;
  const [visible, setVisible] = useState(false);
  const [contactData, setContactData] = useState();

  useEffect(() => {
    getContactAction();
  }, [getContactAction]);

  const deleteModal = (id) => {
    setVisible(true);
    setContactData(id);
  };

  const onDeleteHandler = () => {
    deleteContactAction(contactData, closeModal);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <CompWrapper>
      <Header title="Contacts" />
      <div>
        <Modal visible={visible} onClose={() => setVisible(false)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            Are you sure you want to delete?
            <ActivityIndicator animating={deleteContactLoader}>
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="default"
                  style={{ background: "red", color: "white" }}
                  onClick={() => onDeleteHandler()}
                >
                  {" "}
                  Delete{" "}
                </Button>
                <Button color="secondary" onClick={() => setVisible(false)}>
                  Cancel
                </Button>
              </div>
            </ActivityIndicator>
          </div>
        </Modal>
        <MaterialTable
          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 80,
            },
            { title: "Email", field: "email" },
            {
              title: "Subject",
              field: "subject",
            },
            {
              title: "Message",
              field: "message",
            },
            {
              title: "Date",
              field: "updated_at",
              render: (rowData) => (
                <div style={{ width: 100 }}>
                  {rowData.updated_at.slice(0, 10)}
                </div>
              ),
              // field: "updated_at",
            },
          ]}
          data={contactList?.data}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id);
              },
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            toolbar: false,
            search: false,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
        />
      </div>
    </CompWrapper>
  );
};

// export default OurLeadersPage;
const mapStateToProps = (state) => {
  return {
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContactAction,
      deleteContactAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
