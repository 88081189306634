import {
  GET_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT,
} from "../actions/Actions";

const initalState = {
  loader: false,
  error: false,
  announcementList: [],

  addLoader: false,
  addError: false,

  deleteLoader: false,
  deletEerror: false,
};

export function announcementReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ANNOUNCEMENT.LOADING:
      return { ...state, loader: true, error: false, announcementList: [] };
    case GET_ANNOUNCEMENT.SUCCESS:
      return {
        ...state,
        loader: false,
        error: false,
        announcementList: payload.data,
      };
    case GET_ANNOUNCEMENT.ERROR:
      return { ...state, loader: false, error: true };

    case ADD_ANNOUNCEMENT.LOADING:
      return { ...state, addLoader: true, addError: false };
    case ADD_ANNOUNCEMENT.SUCCESS:
      return {
        ...state,
        addLoader: false,
        addError: false,
      };
    case ADD_ANNOUNCEMENT.ERROR:
      return { ...state, addLoader: false, addError: true };

    case DELETE_ANNOUNCEMENT.LOADING:
      return { ...state, deleteLoader: true, deletEerror: false };
    case DELETE_ANNOUNCEMENT.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
        deletEerror: false,
      };
    case DELETE_ANNOUNCEMENT.ERROR:
      return { ...state, deleteLoader: false, deletEerror: true };

    default:
      return state;
  }
}
