import { ADD_CONTACT_US, GET_CONTACT_US, GET_CONTACT_US_DETAIL, EDIT_CONTACT_US, DELETE_CONTACT_US } from "../actions/Actions";

const initalState = {
  getContactLoader: false,
  deleteContactLoader: false,
  contactList: [],
};

export function contactReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTACT_US.LOADING:
      return { ...state, getContactLoader: true };
    case GET_CONTACT_US.SUCCESS:
      return { ...state, getContactLoader: false, contactList: payload };
    case GET_CONTACT_US.ERROR:
      return { ...state, getContactLoader: false };

    case DELETE_CONTACT_US.LOADING:
      return { ...state, deleteContactLoader: true };
    case DELETE_CONTACT_US.SUCCESS:
      return { ...state, deleteContactLoader: false };
    case DELETE_CONTACT_US.ERROR:
      return { ...state, deleteContactLoader: false };

      default:
      return state;
  }
}
