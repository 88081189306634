import { toast } from "react-toastify";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { LOGIN } from "./ActionTypes.action";

export function loginAction(body, handleLogin) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: LOGIN.LOADING });
      res = await api(`${APIS.login}`, "POST", body);

      const { success, data } = res;
      if (success === "true") {
        dispatch({ type: LOGIN.SUCCESS, payload: data });
        handleLogin("admin", data.data.token);
        toast.success("Login Successful", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        dispatch({ type: LOGIN.ERROR });
        toast.error("Login Error", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: LOGIN.ERROR });
      toast.error("Login Error", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
