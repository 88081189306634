import {
  GET_ACTIVITIES,
  ADD_ACTIVITIES,
  GET_ACTIVITIES_DETAIL,
  EDIT_ACTIVITIES,
  DELETE_ACTIVITIES,
} from "../actions/Actions";

const initalState = {
  getActivitiesLoader: false,
  addActivitiesLoader: false,
  getActivitiesDetailLoader: false,
  editActivitiesLoader: false,
  deleteActivitiesLoader: false,
  activitiesList: [],
  activitiesDetail: null,
};

export function activitiesReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVITIES.LOADING:
      return { ...state, getActivitiesLoader: true };
    case GET_ACTIVITIES.SUCCESS:
      return { ...state, getActivitiesLoader: false, activitiesList: payload };
    case GET_ACTIVITIES.ERROR:
      return { ...state, getActivitiesLoader: false, activitiesList: [] };

    case ADD_ACTIVITIES.LOADING:
      return {
        ...state,
        addActivitiesLoader: true,
        addActivitiesSuccess: false,
      };
    case ADD_ACTIVITIES.SUCCESS:
      return {
        ...state,
        addActivitiesLoader: false,
        addActivitiesSuccess: true,
      };
    case ADD_ACTIVITIES.ERROR:
      return {
        ...state,
        addActivitiesLoader: false,
        addActivitiesSuccess: false,
      };

    case GET_ACTIVITIES_DETAIL.LOADING:
      return { ...state, getActivitiesDetailLoader: true };
    case GET_ACTIVITIES_DETAIL.SUCCESS:
      return {
        ...state,
        getActivitiesDetailLoader: false,
        activitiesDetail: payload,
      };
    case GET_ACTIVITIES_DETAIL.ERROR:
      return { ...state, getActivitiesDetailLoader: false };

    case EDIT_ACTIVITIES.LOADING:
      return { ...state, editActivitiesLoader: true };
    case EDIT_ACTIVITIES.SUCCESS:
      return { ...state, editActivitiesLoader: false };
    case EDIT_ACTIVITIES.ERROR:
      return { ...state, editActivitiesLoader: false };

    case DELETE_ACTIVITIES.LOADING:
      return { ...state, deleteActivitiesLoader: true };
    case DELETE_ACTIVITIES.SUCCESS:
      return { ...state, deleteActivitiesLoader: false };
    case DELETE_ACTIVITIES.ERROR:
      return { ...state, deleteActivitiesLoader: false };

    default:
      return state;
  }
}
