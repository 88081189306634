import { toast } from "react-toastify";
import { GET_ANNOUNCEMENT } from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { ADD_ANNOUNCEMENT, DELETE_ANNOUNCEMENT } from "./ActionTypes.action";

export function getAnnouncementAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ANNOUNCEMENT.LOADING });
      res = await api(APIS.announcement);

      const { success, data } = res;
      if (success === "true") {
        console.log("data>>>>>>>>>>", data);

        dispatch({ type: GET_ANNOUNCEMENT.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_ANNOUNCEMENT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ANNOUNCEMENT.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function postAnnouncementAction(formData, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_ANNOUNCEMENT.LOADING });
      res = await api(APIS.announcement, "POST", formData, "formdata");

      console.log("data>>>>>>>>>>", res);
      const { success, data } = res;
      if (success === "true") {
        toast.success("Announcement Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: ADD_ANNOUNCEMENT.SUCCESS, payload: data });
        dispatch(getAnnouncementAction());
        callback();
      } else {
        toast.error("Announcement adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: ADD_ANNOUNCEMENT.ERROR });
      }
    } catch ({ message }) {
      toast.error("Announcement adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      dispatch({ type: ADD_ANNOUNCEMENT.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function deleteAnnouncementAction(id, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_ANNOUNCEMENT.LOADING });
      res = await api(`${APIS.announcement}/${id}`, "DELETE");

      const { success, data } = res;
      if (success === "true") {
        toast.success("Announcement deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_ANNOUNCEMENT.SUCCESS, payload: data });
        dispatch(getAnnouncementAction());
        callback();
      } else {
        toast.error("Announcement Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_ANNOUNCEMENT.ERROR });
      }
    } catch ({ message }) {
      toast.error("Announcement Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      dispatch({ type: DELETE_ANNOUNCEMENT.ERROR });
      console.error(message);
      return 0;
    }
  };
}
