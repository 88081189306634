import { combineReducers } from "redux";
import { sampleReducer } from "./Sample.reducer";
import { blogReducer } from "./Blog.reducer";
import { loginReducer } from "./Login.reducer";
import { leaderReducer } from "./Leader.reducer";
import { activitiesReducer } from "./Activities.reducer";
import { testimonialReducer } from "./Testimonial.reducer";
import { gallaryReducer } from "./Gallary.reducer";
import { publicationReducer } from "./Publication.reducer";
import { contactReducer } from "./Contact.reducer";
import { vacancyReducer } from "./Vacancy.reducer";
import { announcementReducer } from "./Announcement.reducer";
import { grantReducer } from "./Grant.reducer";

const rootReducer = combineReducers({
  samples: sampleReducer,
  blog: blogReducer,
  login: loginReducer,
  leader: leaderReducer,
  testimonial: testimonialReducer,
  activities: activitiesReducer,
  gallary: gallaryReducer,
  publication: publicationReducer,
  contact: contactReducer,
  vacancy: vacancyReducer,
  announcement: announcementReducer,
  grantApplication: grantReducer,
});

export default rootReducer;
