import React, { useEffect } from "react";
import { useNavigation, Modal } from "react-uicomp";

import { Button, Paper } from "@material-ui/core";
import MaterialTable from "material-table";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGrantAction } from "../../actions/Actions";

import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";
import DefaultButton from "../common/button/Button.common";

const GrantApplicationPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { getGrantAction, grantApplication } = props;
  const { grantList } = grantApplication;
  useEffect(() => {
    getGrantAction();
  }, []);
  return (
    <CompWrapper>
      <Header title="Grant" />
      <div>
        <MaterialTable
          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 80,
            },

            { title: "Name", field: "name" },
            {
              title: "Email",
              field: "email",
            },
            {
              title: "Phone",
              field: "phone",
            },
            {
              title: "Applying For",
              field: "applying_for",
            },
          ]}
          data={grantList}
          detailPanel={(rowData) => {
            return (
              <div>
                {rowData?.pdf?.length > 0 ? (
                  rowData?.pdf?.map((element, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: "10px 20px",
                          alignItems: "center",
                        }}
                      >
                        <div>Document {index + 1}</div>
                        <DefaultButton
                          title="Download"
                          onClick={() => window.open(element)}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div> No data </div>
                )}
              </div>
            );
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          // actions={[
          //   (rowData) => ({
          //     icon: "edit",
          //     tooltip: "Edit",
          //     onClick: (event, rowData) => {
          //       navigate(routes.Leaders.path + "/edit/" + rowData.id);
          //     },
          //   }),
          //   (rowData) => ({
          //     icon: "delete",
          //     tooltip: "Delete",
          //     onClick: (event, rowData) => {
          //       deleteModal(rowData.id);
          //     },
          //   }),
          // ]}
          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            toolbar: false,
            search: false,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
        />
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    grantApplication: state.grantApplication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGrantAction,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrantApplicationPage);
