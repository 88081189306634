import { createActionSet } from "../helpers/Helpers";

// SAMPLE ACTION SET
/* 
  OBJECT STRUCTURE 
  { 
    LOADING: "SAMPLE_LOADING",
    SUCCESS: "SAMPLE_SUCCESS",  
    ERROR: "SAMPLE_ERROR",
    SAMPLE: "SAMPLE"  
  } 
*/
export const SAMPLE = createActionSet("SAMPLE");

// LOGIN
export const LOGIN = createActionSet("LOGIN");

// BLOG
export const ADD_BLOG = createActionSet("ADD_BLOG");
export const GET_BLOG = createActionSet("GET_BLOG");
export const GET_BLOG_DETAIL = createActionSet("GET_BLOG_DETAIL");
export const EDIT_BLOG = createActionSet("EDIT_BLOG");
export const DELETE_BLOG = createActionSet("DELETE_BLOG");

export const ADD_LEADER = createActionSet("ADD_LEADER");
export const GET_LEADER = createActionSet("GET_LEADER");
export const GET_LEADER_DETAIL = createActionSet("GET_LEADER_DETAIL");
export const EDIT_LEADER = createActionSet("EDIT_LEADER");
export const DELETE_LEADER = createActionSet("DELETE_LEADER");

export const ADD_TESTIMONIAL = createActionSet("ADD_TESTIMONIAL");
export const GET_TESTIMONIAL = createActionSet("GET_TESTIMONIAL");
export const GET_TESTIMONIAL_DETAIL = createActionSet("GET_TESTIMONIAL_DETAIL");
export const EDIT_TESTIMONIAL = createActionSet("EDIT_TESTIMONIAL");
export const DELETE_TESTIMONIAL = createActionSet("DELETE_TESTIMONIAL");

export const ADD_ACTIVITIES = createActionSet("ADD_ACTIVITIES");
export const GET_ACTIVITIES = createActionSet("GET_ACTIVITIES");
export const GET_ACTIVITIES_DETAIL = createActionSet("GET_ACTIVITIES_DETAIL");
export const EDIT_ACTIVITIES = createActionSet("EDIT_ACTIVITIES");
export const DELETE_ACTIVITIES = createActionSet("DELETE_ACTIVITIES");

export const ADD_GALLARY = createActionSet("ADD_GALLARY");
export const GET_GALLARY = createActionSet("GET_GALLARY");
export const GET_GALLARY_DETAIL = createActionSet("GET_GALLARY_DETAIL");
export const EDIT_GALLARY = createActionSet("EDIT_GALLARY");
export const DELETE_GALLARY = createActionSet("DELETE_GALLARY");

export const ADD_PUBLICATION = createActionSet("ADD_PUBLICATION");
export const GET_PUBLICATION = createActionSet("GET_PUBLICATION");
export const GET_PUBLICATION_DETAIL = createActionSet("GET_PUBLICATION_DETAIL");
export const EDIT_PUBLICATION = createActionSet("EDIT_PUBLICATION");
export const DELETE_PUBLICATION = createActionSet("DELETE_PUBLICATION");

export const GET_CONTACT_US = createActionSet("GET_CONTACT_US");
export const DELETE_CONTACT_US = createActionSet("DELETE_CONTACT_US");
//VACANCY ACTION SET
export const GET_VACANCY = createActionSet("GET_VACANCY");
export const GET_VACANCY_DETAIL = createActionSet("GET_VACANCY_DETAIL");
export const ADD_VACANCY = createActionSet("ADD_VACANCY");
export const EDIT_VACANCY = createActionSet("EDIT_VACANCY");
export const DELETE_VACANCY = createActionSet("DELETE_VACANCY");

export const GET_VACANCY_APPLICATION = createActionSet(
  "GET_VACANCY_APPLICATION"
);

//ANNOUNCEMENT ACTION SET
export const GET_ANNOUNCEMENT = createActionSet("GET_ANNOUNCEMENT");
export const ADD_ANNOUNCEMENT = createActionSet("ADD_ANNOUNCEMENT");
export const DELETE_ANNOUNCEMENT = createActionSet("DELETE_ANNOUNCEMENT");

//GRANT ACTION SET 
export const GRANT = createActionSet("GRANT")