import {
  GET_PUBLICATION,
  ADD_PUBLICATION,
  GET_PUBLICATION_DETAIL,
  EDIT_PUBLICATION,
  DELETE_PUBLICATION,
} from "./ActionTypes.action";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { toast } from "react-toastify";

export function getPublicationAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_PUBLICATION.LOADING });
      res = await api(`${APIS.publications}/all`);
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_PUBLICATION.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_PUBLICATION.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_PUBLICATION.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addPublicationAction(
  body,
  formdata,
  fileData,
  handleRedirection
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_PUBLICATION.LOADING });

      res = await api(`${APIS.publications}`, "POST", body);

      const { success, data } = res;

      if (success) {
        let response = await api(
          `${APIS.publications}/${data.data.id}/addimage`,
          "PATCH",
          formdata,
          "formdata"
        );
        if (response.success === "true") {
          if (!!fileData) {
            let fileresponse = await api(
              `${APIS.publications}/${data.data.id}/addpdf`,
              "PATCH",
              fileData,
              "formdata"
            );
            if (fileresponse.success === "true") {
              toast.success("Publication Added Successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
              dispatch({ type: ADD_PUBLICATION.SUCCESS });
              handleRedirection();
            } else {
              dispatch({ type: ADD_PUBLICATION.ERROR });
              toast.error("Publication Adding Failed", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }
          } else {
            toast.success("Publication Added Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            dispatch({ type: ADD_PUBLICATION.SUCCESS });
            handleRedirection();
          }
        } else {
          dispatch({ type: ADD_PUBLICATION.ERROR });
          toast.error("Publication Adding Failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      } else {
        dispatch({ type: ADD_PUBLICATION.ERROR });
        toast.error("Publication Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_PUBLICATION.ERROR });
      toast.error("Publication Adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getPublicationDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_PUBLICATION_DETAIL.LOADING });
      res = await api(`${APIS.publications}/${id}`);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_PUBLICATION_DETAIL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_PUBLICATION_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_PUBLICATION_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editPublicationAction(id, formData, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_PUBLICATION.LOADING });

      res = await api(
        `${APIS.publications}/${id}`,
        "PATCH",
        formData,
        "formdata"
      );

      const { success, data } = res;

      if (success) {
        toast.success("Publication Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_PUBLICATION.SUCCESS, payload: data });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_PUBLICATION.ERROR });
        toast.error("Publication Editing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_PUBLICATION.ERROR });
      toast.error("Publication Editing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function deletePublicationAction(id, closeModal) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_PUBLICATION.LOADING });
      res = await api(`${APIS.publications}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Publication deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_PUBLICATION.SUCCESS, payload: data });
        closeModal();
        dispatch(getPublicationAction());
      } else {
        dispatch({ type: DELETE_PUBLICATION.ERROR });
        toast.error("Publication Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_PUBLICATION.ERROR });
      toast.error("Publication Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
