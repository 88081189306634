import { GRANT } from "./Actions";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";

export function getGrantAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GRANT.LOADING });
      res = await api(APIS.grantApplication);

      const { success, data } = res;

      if (success === "true") {
        dispatch({ type: GRANT.SUCCESS, payload: data.data });
      } else {
        dispatch({ type: GRANT.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GRANT.ERROR });
      console.error(message);
      return 0;
    }
  };
}
