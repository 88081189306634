import LoginPage from "../loginPage/LoginPage";
import SigninPage from "../signinPage/SigninPage";
import DashboardPage from "../dashboardPage/DashboardPage";
import NotFoundPage from "../notFoundPage/NotFoundPage";
import GallaryPage from "../gallaryPage/GallaryPage";
import BlogPage from "../blogPage/BlogPage";
import OurLeadersPage from "../ourLeadersPage/OurLeadersPage";
import TestimonialPage from "../testimonialPage/TestimonialPage";
import PublicationsPage from "../publicationsPage/PublicationsPage";
import AddBlogPage from "../blogPage/AddBlog";
import EditBlogPage from "../blogPage/EditBlogPage";
import AddLeaderPage from "../ourLeadersPage/addLeaderPage/AddLeaderPage";
import EditLeaderPage from "../ourLeadersPage/editLeaderPage/EditLeaderPage";
import AddTestimonialPage from "../testimonialPage/addTestimonialPage/AddTestimonialPage";
import EditTestimonialPage from "../testimonialPage/editTestimonialPage/EditTestimonialPage";
import ActivitiesPage from "../activitiesPage/ActivitiesPage";
import AddActivitiesPage from "../activitiesPage/addActivitiesPage/AddActivitiesPage";
import EditActivitiesPage from "../activitiesPage/editActivitiesPage/EditActivitiesPage";
import AddGallaryPage from "../gallaryPage/addGallaryPage/AddGallaryPage";
import EditGallaryPage from "../gallaryPage/editGallaryPage/EditGallaryPage";
import AddPublicationPage from "../publicationsPage/addPublicationPage/AddPublicationPage";
import EditPublicationPage from "../publicationsPage/editPublicationPage/EditPublicationPage";
import ContactUsPage from "../contactUsPage/ContactUsPage";
import AddUserPage from "../addUserPage/AddUserPage";
import LeaderListPage from "../ourLeadersPage/allLeadersList";
import ResearcherPage from "../ourLeadersPage/ResearcherPage";
import GranteePage from "../ourLeadersPage/GranteePage";
import ActivitiesListPage from "../activitiesPage/ActivitiesListPage";
import TrainingPage from "../activitiesPage/TrainingPage";
import ResearchPage from "../activitiesPage/ResearchPage";
import GrantPage from "../activitiesPage/GrantPage";
import ConsultancyPage from "../activitiesPage/ConsultancyPage";
import VacancyPage from "../vacancyPage/VacancyPage";
import AddVacancyPage from "../vacancyPage/addVacancy/AddVacancyPage";
import EditVacancyPage from "../vacancyPage/editVacancy/EditVacancyPage";
import VacancyPostsList from "../vacancyPage/VacancyPostsList";
import ApplicationsListPage from "../vacancyPage/ApplicationsListPage";
import AnnouncementPage from "../announcementPage/AnnouncementPage";
import GrantApplicationPage from "../grantPage/GrantPage";

export const PUBLICPATHS = [
  {
    key: "Home",
    name: "Home",
    path: "/",
    component: LoginPage,
    restricted: true,
  },
  {
    key: "Login",
    name: "Login",
    path: "/log-in",
    component: LoginPage,
    restricted: true,
  },
  {
    key: "Signin",
    name: "Signin",
    path: "/sign-in",
    component: SigninPage,
    restricted: true,
  },
  {
    path: null,
    component: NotFoundPage,
  },
];

export const PRIVATEPATHS = [
  {
    key: "Blog",
    name: "Blog",
    path: "/blog",
    component: BlogPage,
  },
  {
    key: "AddBlog",
    name: "Add Blog",
    path: "/blog/add",
    component: AddBlogPage,
  },
  {
    key: "EditBlog",
    name: "Edit Blog",
    path: "/blog/edit/:id",
    component: EditBlogPage,
  },
  {
    key: "Gallary",
    name: "Gallary",
    path: "/gallary",
    component: GallaryPage,
  },
  {
    key: "AddGallary",
    name: "Add Gallary",
    path: "/gallary/add",
    component: AddGallaryPage,
  },
  {
    key: "EditGallary",
    name: "Edit Gallary",
    path: "/gallary/edit/:id",
    component: EditGallaryPage,
  },
  // {
  //   key: "Leaders",
  //   name: "Leaders",
  //   path: "/our-leaders",
  //   component: OurLeadersPage,
  // },

  {
    key: "Leaders",
    name: "Leaders",
    path: "/our-leaders",
    component: LeaderListPage,
    nestedPaths: [
      {
        key: "RootLeaders1",
        name: "RootLeaders1",
        path: "/",
        component: OurLeadersPage,
      },
      {
        key: "Leaders1",
        name: "Leaders1",
        path: "/leaders",
        component: OurLeadersPage,
      },
      {
        key: "Researcher",
        name: "Researcher",
        path: "/researcher",
        component: ResearcherPage,
      },
      {
        key: "Grantee",
        name: "Grantee",
        path: "/grantee",
        component: GranteePage,
      },
    ],
  },

  {
    key: "AddLeader",
    name: "Add Leaders",
    path: "/our-leaders/add",
    component: AddLeaderPage,
  },
  {
    key: "EditLeader",
    name: "Edit Leaders",
    path: "/our-leaders/edit/:id",
    component: EditLeaderPage,
  },
  // {
  //   key: "Testimonial",
  //   name: "Testimonial",
  //   path: "/testimonial",
  //   component: TestimonialPage,
  // },
  // {
  //   key: "AddTestimonial",
  //   name: "Add Testimonial",
  //   path: "/testimonial/add",
  //   component: AddTestimonialPage,
  // },
  // {
  //   key: "EditTestimonial",
  //   name: "Edit Testimonial",
  //   path: "/testimonial/edit/:id",
  //   component: EditTestimonialPage,
  // },
  {
    key: "Activities",
    name: "Activities",
    path: "/activities",
    component: ActivitiesListPage,
    nestedPaths: [
      {
        key: "Training",
        name: "Training",
        path: "/",
        component: TrainingPage,
      },
      {
        key: "Training",
        name: "Training",
        path: "/training",
        component: TrainingPage,
      },
      {
        key: "Research",
        name: "Research",
        path: "/research",
        component: ResearchPage,
      },
      {
        key: "Grant",
        name: "Grant",
        path: "/grant",
        component: GrantPage,
      },
      {
        key: "consultancy",
        name: "Consultancy",
        path: "/consultancy",
        component: ConsultancyPage,
      },
    ],
  },
  {
    key: "AddActivities",
    name: "Add Activities",
    path: "/activities/add",
    component: AddActivitiesPage,
  },
  {
    key: "EditActivities",
    name: "Edit Activities",
    path: "/activities/edit/:id",
    component: EditActivitiesPage,
  },
  {
    key: "Publications",
    name: "Publications",
    path: "/publications",
    component: PublicationsPage,
  },
  {
    key: "AddPublication",
    name: "Add Publication",
    path: "/publications/add",
    component: AddPublicationPage,
  },
  {
    key: "EditPublication",
    name: "Edit Publication",
    path: "/publications/edit/:id",
    component: EditPublicationPage,
  },
  {
    key: "ContactUs",
    name: "Contact Us",
    path: "/contact",
    component: ContactUsPage,
  },
  {
    key: "User",
    name: "User",
    path: "/user",
    component: AddUserPage,
  },
  {
    key: "Vacancy",
    name: "Vacancy",
    path: "/vacancy",
    component: VacancyPage,
    nestedPaths: [
      {
        key: "VacancyList",
        name: "VacancyList",
        path: "/",
        component: VacancyPostsList,
      },
      {
        key: "VacancyList",
        name: "VacancyList",
        path: "/vacancylist",
        component: VacancyPostsList,
      },
      {
        key: "Application",
        name: "Application",
        path: "/application",
        component: ApplicationsListPage,
      },
    ],
  },
  {
    key: "AddVacancy",
    name: "Add Vacancy",
    path: "/vacancy/add",
    component: AddVacancyPage,
  },
  {
    key: "EditVacancy",
    name: "Edit Vacancy",
    path: "/vacancy/edit/:id",
    component: EditVacancyPage,
  },
  {
    key: "Announcement",
    name: "Announcement",
    path: "/announcement",
    component: AnnouncementPage,
  },
  {
    key: "GrantApplication",
    name: "Grant Application",
    path: "/grant",
    component: GrantApplicationPage,
  },
];
