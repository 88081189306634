import React, { useEffect, useState } from "react";
import { InvertedButton } from "../common/button/Button.common";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ImageUploading from "react-images-uploading";
import {
  getAnnouncementAction,
  deleteAnnouncementAction,
  postAnnouncementAction,
} from "../../actions/Actions";
import { Modal } from "react-uicomp";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import { Button } from "@material-ui/core";

const AnnouncementPage = (props) => {
  const {
    getAnnouncementAction,
    announcement,
    deleteAnnouncementAction,
    postAnnouncementAction,
  } = props;

  const { announcementList, loader, addLoader, deleteLoader } = announcement;

  const [visible, setVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);

  const [image, setImage] = useState();

  useEffect(() => {
    getAnnouncementAction();
  }, []);

  const onDeleteHandler = () => {
    deleteAnnouncementAction(announcementList[0].id, () => setVisible(false));
  };

  const onAddAnnouncement = () => {
    let formData = new FormData();
    formData.append("images", image);
    postAnnouncementAction(formData, () => setAddVisible(false));
  };

  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };
  console.log("alksjdlkasjdlakjdlkajsd", image);
  return (
    <CompWrapper>
      <Header title="Announcement" />

      <Modal visible={addVisible} onOutsideClick={() => setAddVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          Add Image
          <ImageUploading multiple={false} onChange={onChangeImage}>
            {({ imageList, onImageUpload }) => (
              <div className="upload__image-wrapper">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat( auto-fill, minmax(200px, 1fr) )",
                    columnGap: 10,
                  }}
                >
                  {imageList.map((image) => (
                    <div key={image.key}>
                      <img
                        src={image.dataURL}
                        alt=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  ))}
                </div>

                <ActivityIndicator animating={addLoader}>
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={onImageUpload}
                      variant="contained"
                      color="secondary"
                    >
                      Choose Image
                    </Button>
                    <div>
                      {!!image && (
                        <Button
                          onClick={onAddAnnouncement}
                          variant="contained"
                          color="primary"
                        >
                          Upload
                        </Button>
                      )}
                      <Button
                        color="secondary"
                        onClick={() => setAddVisible(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </ActivityIndicator>
              </div>
            )}
          </ImageUploading>
        </div>
      </Modal>

      <Modal visible={visible} onOutsideClick={() => setVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          Are you sure you want to delete?
          <ActivityIndicator animating={deleteLoader}>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="default"
                style={{ background: "red", color: "white" }}
                onClick={() => onDeleteHandler()}
              >
                {" "}
                Delete{" "}
              </Button>
              <Button color="secondary" onClick={() => setVisible(false)}>
                Cancel
              </Button>
            </div>
          </ActivityIndicator>
        </div>
      </Modal>
      <div>
        {announcementList?.length < 1 && !loader && (
          <InvertedButton
            title="Add Announcement"
            onClick={() => {
              setAddVisible(true);
            }}
          />
        )}
        {announcementList?.length > 0 && !loader && (
          <div>
            <InvertedButton
              title="Delete Announcement"
              onClick={() => setVisible(true)}
            />
            <div
              style={{
                marginTop: 50,
                boxShadow: "0px 0px 0px rgba(0,0,0,0.16)",
                height: "50vh",
              }}
            >
              <img
                src={announcementList[0].image}
                style={{ height: "50vh", objectFit: "contain" }}
              />
            </div>
          </div>
        )}
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    announcement: state.announcement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAnnouncementAction,
      deleteAnnouncementAction,
      postAnnouncementAction,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementPage);
