import React, { useEffect } from "react";
import { useNavigation } from "react-uicomp";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBlogDetailAction } from "../../actions/Actions";

import CompWrapper from "../hocs/CompWrapper.hoc";
import Header from "../common/header/Header.common";
import EditComponent from "./edit/EditComponent";

const EditBlogPage = (props) => {
  const { params } = useNavigation();
  const { getBlogDetailAction, blog } = props;
  const { getBlogDetailLoader, blogDetail } = blog;

  useEffect(() => {
    getBlogDetailAction(params.id);
  }, []);

  return (
    <CompWrapper>
      <Header title="Edit Blog" />
      {blogDetail && !getBlogDetailLoader ? (
        <EditComponent editData={blogDetail} />
      ) : (
        <div>loading...</div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBlogDetailAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBlogPage);
