import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import ImageUploading from "react-images-uploading";
import { TextField, Button } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addPublicationAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import DefaultButton from "../../common/button/Button.common";

const AddPublicationPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const { addPublicationAction, publication } = props;
  const { addPublicationLoader } = publication;
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState();

  const onSubmit = (data) => {
    let fileData = new FormData();
    if (file === undefined || file[0] === undefined) {
    } else {
      fileData.append("pdf", file[0]);
    }

    let formData = new FormData();
    formData.append("images", image);
    let body = {
      title: data.title,
      description: data.description,
    };

    console.log("showFileError", fileData.has("pdf"));
    if (image) {
      fileData.has("pdf")
        ? addPublicationAction(body, formData, fileData, handleRedirection)
        : addPublicationAction(body, formData, null, handleRedirection);
    } else {
      setImageError(true);
    }
  };

  const handleRedirection = () => {
    navigate(routes.Publications.path);
  };

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  const onChangeFile = (event) => {
    setFile([event.target.files[0]]);
  };
  return (
    <CompWrapper>
      <Header title="Add Publication" />
      <div className="addblog-container">
        <div className="addblog">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
          >
            <TextField
              name="title"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Title"
            />
            <TextField
              name="description"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Description"
            />

            <ImageUploading multiple={false} onChange={onChangeImage}>
              {({ imageList, onImageUpload }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}
                  >
                    {imageList.map((image) => (
                      <div
                        key={image.key}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                        }}
                      >
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={onImageUpload}
                    variant="contained"
                    color="secondary"
                  >
                    Upload Image
                  </Button>
                </div>
              )}
            </ImageUploading>

            {imageError && (
              <div style={{ color: "red" }}>
                <strong>Image is Required!!</strong>
              </div>
            )}

            {/* <input type="file" onChange={(event) => { onChangeFile(event) }} /> */}
            <div
              style={{
                position: "relative",
                display: "inline-block",
                overflow: "hidden",
                padding: 10,
              }}
            >
              <input
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  opacity: 0,
                  fontSize: 50,
                }}
                type="file"
                multiple
                name="thumbnail"
                onChange={(event) => {
                  onChangeFile(event);
                }}
              />
              <DefaultButton onClick={() => false} title="Add Pdf File" />
            </div>
            {file && <div className="text-rumpel">{file[0]?.name}</div>}
            <ActivityIndicator animating={addPublicationLoader}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

// export default AddPublicationPage;
const mapStateToProps = (state) => {
  return {
    publication: state.publication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addPublicationAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPublicationPage);
