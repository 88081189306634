import React, { useEffect, useState } from "react";
import { useNavigation } from "react-uicomp";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGallaryDetailAction, editGallaryAction } from "../../../actions/Actions";
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import Header from "../../common/header/Header.common";

const EditGallaryPage = (props) => {
  const { params, navigation } = useNavigation();
  const { navigate, routes } = navigation;

  const { getGallaryDetailAction, gallary, editGallaryAction } = props;
  const { getGallaryDetailLoader, editGallaryLoader, gallaryDetail } = gallary;

  const { handleSubmit, register, setValue, control } = useForm();
  const [image, setImage] = useState();

  useEffect(() => {
    getGallaryDetailAction(params.id)
  }, [])

  const onSubmit = async (data) => {
    let formData = new FormData();
    if (!!image) {

      await formData.append("images", image);
    }
    await formData.append("title", data.title);

    editGallaryAction(params.id, formData, handleRedirection);

  };

  const handleRedirection = () => {
    navigate(routes["Gallary"].path);
  };


  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };

  return (
    <CompWrapper>
      <Header title="Edit gallary" />
      {gallaryDetail && !getGallaryDetailLoader ?
        <div className="addblog-container">
          <div className="addblog">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
            >
              <TextField
                defaultValue={gallaryDetail.data.title}
                name="title"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Title"
              />


              <ImageUploading multiple={false} onChange={onChangeImage} defaultValue={gallaryDetail.data.images ? [{ "dataURL": gallaryDetail.data.images[0] }] : null} >
                {({ imageList, onImageUpload }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}
                    >
                      {imageList.map((image) => (
                        <div
                          key={image.key}
                          style={{
                            position: "relative",
                            width: 200,
                            height: 200,
                          }}
                        >
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      onClick={onImageUpload}
                      variant="contained"
                      color="secondary"
                    >
                      Change Image
                </Button>
                  </div>
                )}
              </ImageUploading>


              <ActivityIndicator animating={editGallaryLoader}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
            </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
        : <div>loading...</div>}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    gallary: state.gallary,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGallaryDetailAction, editGallaryAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGallaryPage);
