import React, { useState } from "react";
import { Auth, Navigation } from "react-uicomp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideNavigation from "../common/sideNavigation/SideNavigation.common";
import { PUBLICPATHS, PRIVATEPATHS } from "./routes";
import { USER_ROLES } from "./userRoles";
// return `Auth.Provider` with `Auth.Screens` inside it
const App = () => {
  const isLoggedIn = localStorage.getItem("isLoggedin") ? true : false;
  const userRole = localStorage.getItem("userRole")
    ? localStorage.getItem("userRole")
    : "user";
  const token = localStorage.getItem("token");

  const [sideNavVisible, setSideNavVisible] = useState(false);
  const [fullPageLoaderVisible, setFullpageLoaderVisible] = useState(false);
  const [config, setConfig] = useState({
    isLoggedIn: isLoggedIn,
    userRole: userRole,
    token_id: token,
  });

  return (
    <Navigation.Provider
      publicPaths={PUBLICPATHS}
      privatePaths={PRIVATEPATHS}
      userRoles={USER_ROLES}
      routerType="hash"
    >
      <Auth.Provider
        config={config}
        state={{
          setSideNavVisible,
          setFullpageLoader: (val) => setFullpageLoaderVisible(val),
          handleLogin: (userRole, token) => {
            localStorage.setItem("isLoggedin", true);
            localStorage.setItem("userRole", userRole);
            localStorage.setItem("token", token);
            setConfig({
              isLoggedIn: true,
              userRole: userRole,
              token_id: token,
            });
          },
          handleLogout: () => {
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("userRole");
            localStorage.removeItem("token");
            setConfig({
              isLoggedIn: false,
              userRole: "user",
              token_id: null,
            });
          },
        }}
      >
        <Auth.Screens />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
        <SideNavigation
          mobileSideNavVisible={sideNavVisible}
          setMobileSideNavVisible={setSideNavVisible}
        />
      </Auth.Provider>
    </Navigation.Provider>
  );
};

export default App;
