import React, { useEffect, useState } from "react";
import { useNavigation, Modal } from "react-uicomp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import { Button, Paper } from "@material-ui/core";

import {
  getVacancyApplicationAction,
  deleteVacancyAction,
} from "../../actions/Actions";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import DefaultButton from "../common/button/Button.common";

const AppicationListPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { getVacancyApplicationAction, vacancy, deleteVacancyAction } = props;
  const { applicationList } = vacancy;
  const [visible, setVisible] = useState(false);
  const [activeId, setActiveId] = useState();

  useEffect(() => {
    getVacancyApplicationAction();
  }, []);

  const deleteModal = (id) => {
    setVisible(true);
    setActiveId(id);
  };

  const onDeleteHandler = () => {
    deleteVacancyAction(activeId, closeModal);
  };

  const closeModal = () => {
    setVisible(false);
  };
  console.log("applicationList", applicationList);

  return (
    <div>
      <Modal visible={visible} onOutsideClick={() => setVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          Are you sure you want to delete?
          <ActivityIndicator animating={false}>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="default"
                style={{ background: "red", color: "white" }}
                onClick={() => onDeleteHandler()}
              >
                {" "}
                Delete{" "}
              </Button>
              <Button color="secondary" onClick={() => setVisible(false)}>
                Cancel
              </Button>
            </div>
          </ActivityIndicator>
        </div>
      </Modal>
      <MaterialTable
        columns={[
          {
            title: "S.N.",
            field: "tableData.id",
            render: (rowData) => rowData.tableData.id + 1,
            width: 80,
          },

          { title: "Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
        ]}
        data={applicationList}
        detailPanel={(rowData) => {
          return (
            <div>
              {rowData?.pdf?.length > 0 ? (
                rowData?.pdf?.map((element, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "10px 20px",
                        alignItems: "center",
                      }}
                    >
                      <div>Document {index + 1}</div>
                      <DefaultButton
                        title="Download"
                        onClick={() => window.open(element)}
                      />
                    </div>
                  );
                })
              ) : (
                <div> No data </div>
              )}
            </div>
          );
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        actions={[]}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          toolbar: false,
          search: false,
          headerStyle: {
            fontWeight: "bold",
            fontSize: 16,
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVacancyApplicationAction,
      deleteVacancyAction,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AppicationListPage);
