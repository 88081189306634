import React from "react";
import { useNavigation, useAuth } from "react-uicomp";
import { NavLink } from "react-router-dom";
import {
  MdClose,
  MdDashboard,
  FaBloggerB,
  MdPhotoLibrary,
  IoMdPeople,
  MdComment,
  GiPapers,
  MdEventAvailable,
  MdContactMail,
} from "react-icons/all";

import Logo from "../../../assets/logo192.png";
import { CSSTransition } from "react-transition-group";

const SideNav = () => {
  const { navigation } = useNavigation();
  const { setSideNavVisible } = useAuth();
  const { routes } = navigation;

  return (
    <div className="sidenavigation-container">
      <div className="sidenavigation">
        <div className="sidenavigation-header">
          <img src={Logo} alt="Now Test Logo" />
        </div>

        <ul
          className="sidenavigation-list"
          onClick={() => setSideNavVisible(false)}
        >
          {routes.Blog && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Blog.path} activeClassName="active">
                <span className="icon">
                  <FaBloggerB />
                </span>
                <span className="title">{routes.Blog.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Activities && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Activities.path} activeClassName="active">
                <span className="icon">
                  <MdEventAvailable />
                </span>
                <span className="title">{routes.Activities.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Gallary && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Gallary.path} activeClassName="active">
                <span className="icon">
                  <MdPhotoLibrary />
                </span>
                <span className="title">{routes.Gallary.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Leaders && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Leaders.path} activeClassName="active">
                <span className="icon">
                  <IoMdPeople />
                </span>
                <span className="title">{routes.Leaders.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Testimonial && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Testimonial.path} activeClassName="active">
                <span className="icon">
                  <MdComment />
                </span>
                <span className="title">{routes.Testimonial.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Publications && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Publications.path} activeClassName="active">
                <span className="icon">
                  <GiPapers />
                </span>
                <span className="title">{routes.Publications.name}</span>
              </NavLink>
            </li>
          )}
          {routes.ContactUs && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.ContactUs.path} activeClassName="active">
                <span className="icon">
                  <MdContactMail />
                </span>
                <span className="title">{routes.ContactUs.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Vacancy && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Vacancy.path} activeClassName="active">
                <span className="icon">
                  <MdContactMail />
                </span>
                <span className="title">{routes.Vacancy.name}</span>
              </NavLink>
            </li>
          )}
          {routes.Announcement && (
            <li className="sidenavigation-list-item">
              <NavLink to={routes.Announcement.path} activeClassName="active">
                <span className="icon">
                  <MdContactMail />
                </span>
                <span className="title">{routes.Announcement.name}</span>
              </NavLink>
            </li>
          )}
          {routes.GrantApplication && (
            <li className="sidenavigation-list-item">
              <NavLink
                to={routes.GrantApplication.path}
                activeClassName="active"
              >
                <span className="icon">
                  <MdContactMail />
                </span>
                <span className="title">{routes.GrantApplication.name}</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default function SideNavigation(props) {
  const { mobileSideNavVisible, setMobileSideNavVisible } = props;
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return (
      <>
        <CSSTransition
          in={mobileSideNavVisible}
          timeout={200}
          classNames="sidenavigation-node"
          unmountOnExit
        >
          <div className="sidenavigation-mobile-container">
            <div className="sidenavigation-mobile">
              <SideNav />

              <div
                className="close-sidenav"
                onClick={() => setMobileSideNavVisible(false)}
              >
                <MdClose />
              </div>
            </div>
          </div>
        </CSSTransition>

        <div className="sidenavigation-web">
          <SideNav />
        </div>
      </>
    );
  } else {
    return null;
  }
}
