import { ADD_PUBLICATION, GET_PUBLICATION, GET_PUBLICATION_DETAIL, EDIT_PUBLICATION, DELETE_PUBLICATION } from "../actions/Actions";

const initalState = {
  getPublicationLoader: false,
  addPublicationLoader: false,
  getPublicationDetailLoader:false,
  editPublicationLoader: false,
  deletePublicationLoader: false,
  publicationList: [],
  publicationDetail:null
};

export function publicationReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PUBLICATION.LOADING:
      return { ...state, getPublicationLoader: true };
    case GET_PUBLICATION.SUCCESS:
      return { ...state, getPublicationLoader: false, publicationList: payload };
    case GET_PUBLICATION.ERROR:
      return { ...state, getPublicationLoader: false };

    case ADD_PUBLICATION.LOADING:
      return { ...state, addPublicationLoader: true, addPublicationSuccess: false };
    case ADD_PUBLICATION.SUCCESS:
      return { ...state, addPublicationLoader: false, addPublicationSuccess: true };
    case ADD_PUBLICATION.ERROR:
      return { ...state, addPublicationLoader: false, addPublicationSuccess: false };

    case GET_PUBLICATION_DETAIL.LOADING:
      return { ...state, getPublicationDetailLoader: true };
    case GET_PUBLICATION_DETAIL.SUCCESS:
      return { ...state, getPublicationDetailLoader: false ,publicationDetail:payload};
    case GET_PUBLICATION_DETAIL.ERROR:
      return { ...state, getPublicationDetailLoader: false };

    case EDIT_PUBLICATION.LOADING:
      return { ...state, editPublicationLoader: true };
    case EDIT_PUBLICATION.SUCCESS:
      return { ...state, editPublicationLoader: false };
    case EDIT_PUBLICATION.ERROR:
      return { ...state, editPublicationLoader: false };

    case DELETE_PUBLICATION.LOADING:
      return { ...state, deletePublicationLoader: true };
    case DELETE_PUBLICATION.SUCCESS:
      return { ...state, deletePublicationLoader: false };
    case DELETE_PUBLICATION.ERROR:
      return { ...state, deletePublicationLoader: false };

      default:
      return state;
  }
}
