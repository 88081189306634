import React, { useState, useEffect } from "react";
import { useNavigation, Modal } from "react-uicomp";
import MaterialTable from "material-table";
import { Button,Paper } from "@material-ui/core";
import CompWrapper from "../hocs/CompWrapper.hoc";
import Header from "../common/header/Header.common";
import { Edit, Delete } from "@material-ui/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBlogAction, deleteBlogAction } from "../../actions/Actions";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

const BlogPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { getBlogAction, blog, deleteBlogAction } = props;
  const { blogList, getBlogLoader, deleteBlogLoader } = blog;
  const [visible, setVisible] = useState(false);
  const [blogData, setBlogData] = useState();

  const toAddPage = () => {
    navigate(routes.AddBlog.path);
  };

  useEffect(() => {
    getBlogAction();
  }, [getBlogAction]);

  const deleteModal =(id)=>{
    setVisible(true)
    setBlogData(id)
  }
  
  const onDeleteHandler =()=>{
    deleteBlogAction(blogData,closeModal)
  }
  
  const closeModal =()=>{
    setVisible(false)
  }

  return (
    <CompWrapper>
      <Header title="Blog" />
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div style={{display:'flex',flexDirection:'column'}}>
                Are you sure you want to delete?
                  <ActivityIndicator animating={deleteBlogLoader}>
                <div style={{ marginTop: 20,    display: "flex",    justifyContent: "flex-end"}}>

                <Button color="default" style={{background:'red',color:'white'}}  onClick={()=>onDeleteHandler()}> Delete </Button>
                <Button color="secondary" onClick={() => setVisible(false)} >Cancel</Button>
                </div>
                  </ActivityIndicator>
          </div>                
      </Modal>
      <div>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => toAddPage()}
        >
          add blog
        </Button>
        <MaterialTable
          title="Blog List"
          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 80,
            },
            {
              title: "Image",
              field: "images",
              render: (rowData) => (
                <img
                  alt="MediaImage"
                  style={{ height: 100, borderRadius: 4 }}
                  src={rowData.images && rowData.images[0]}
                />
              ),
            },
            { title: "Title", field: "title" },
            {
              title: "Desc",
              field: "description",
              render: (rowData) =>
                rowData.description &&
                rowData.description
                  .replace(/<\/?[^>]+(>|$)/g, "")
                  .slice(0, 100),
            },
          ]}
          data={blogList?.data}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                navigate(routes.Blog.path+"/edit/"+rowData.id)
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id)
              },
            }),
          ]}

          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            toolbar:false,
            search: false,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
        />
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBlogAction,deleteBlogAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);
