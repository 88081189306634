import {
  ADD_VACANCY,
  DELETE_VACANCY,
  EDIT_VACANCY,
  GET_VACANCY,
  GET_VACANCY_APPLICATION,
  GET_VACANCY_DETAIL,
  VACANCY,
} from "../actions/Actions";

const initalState = {
  loading: false,
  error: false,
  vacancyList: [],
  detail: null,
  detailLoader: false,
  detailError: false,

  addLoader: false,
  addError: false,

  editLoader: false,
  editError: false,

  applicationLoader: false,
  applicationError: false,
  applicationList: [],

  deleteLoader: false,
  deleteError: false,
};

export function vacancyReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VACANCY.LOADING:
      return { ...state, loading: true, error: false };
    case GET_VACANCY.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        vacancyList: payload.data,
      };
    case GET_VACANCY.ERROR:
      return { ...state, loading: false, error: true };

    case GET_VACANCY_DETAIL.LOADING:
      return { ...state, detailLoader: true, detailError: false };
    case GET_VACANCY_DETAIL.SUCCESS:
      return {
        ...state,
        detailLoader: false,
        detailError: false,
        detail: payload.data,
      };
    case GET_VACANCY_DETAIL.ERROR:
      return { ...state, detailLoader: false, detailError: true };

    case ADD_VACANCY.LOADING:
      return { ...state, addLoader: true, addError: false };
    case ADD_VACANCY.SUCCESS:
      return {
        ...state,
        addLoader: false,
        addError: false,
      };
    case ADD_VACANCY.ERROR:
      return { ...state, addLoader: false, addError: true };

    case EDIT_VACANCY.LOADING:
      return { ...state, editLoader: true, editError: false };
    case EDIT_VACANCY.SUCCESS:
      return {
        ...state,
        editLoader: false,
        editError: false,
      };
    case EDIT_VACANCY.ERROR:
      return { ...state, editLoader: false, editError: true };

    case GET_VACANCY_APPLICATION.LOADING:
      return { ...state, applicationLoader: true, applicationError: false };
    case GET_VACANCY_APPLICATION.SUCCESS:
      return {
        ...state,
        applicationLoader: false,
        applicationError: false,
        applicationList: payload.data.data,
      };
    case GET_VACANCY_APPLICATION.ERROR:
      return { ...state, applicationLoader: false, applicationError: true };

    case DELETE_VACANCY.LOADING:
      return { ...state, deleteLoader: true, deleteError: false };
    case DELETE_VACANCY.SUCCESS:
      return {
        ...state,
        deleteLoader: false,
        deleteError: false,
      };
    case DELETE_VACANCY.ERROR:
      return { ...state, deleteLoader: false, deleteError: true };

    default:
      return state;
  }
}
