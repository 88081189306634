import {
  GET_ACTIVITIES,
  ADD_ACTIVITIES,
  GET_ACTIVITIES_DETAIL,
  EDIT_ACTIVITIES,
  DELETE_ACTIVITIES,
} from "./ActionTypes.action";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { toast } from "react-toastify";

export function getActivitiesAction(value) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ACTIVITIES.LOADING });
      res = await api(`${APIS.activities}/all?type=${value}`);
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_ACTIVITIES.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_ACTIVITIES.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ACTIVITIES.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addActivitiesAction(
  body,
  formdata,
  fileData,
  handleRedirection
) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_ACTIVITIES.LOADING });

      res = await api(`${APIS.activities}`, "POST", body);

      const { success, data } = res;

      if (success) {
        let response = await api(
          `${APIS.activities}/${data.data.id}/addimage`,
          "PATCH",
          formdata,
          "formdata"
        );
        if (response.success === "true") {
          if (!!fileData) {
            let fileresponse = await api(
              `${APIS.activities}/${data.data.id}/addpdf`,
              "PATCH",
              fileData,
              "formdata"
            );
            if (fileresponse.success === "true") {
              toast.success("Activity Added Successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
              dispatch({ type: ADD_ACTIVITIES.SUCCESS });
              handleRedirection();
            } else {
              dispatch({ type: ADD_ACTIVITIES.ERROR });
              toast.error("Activity Adding Failed", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }
          } else {
            toast.success("Activity Added Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            dispatch({ type: ADD_ACTIVITIES.SUCCESS });
            handleRedirection();
          }
        } else {
          dispatch({ type: ADD_ACTIVITIES.ERROR });
          toast.error("Activity Adding Failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      } else {
        dispatch({ type: ADD_ACTIVITIES.ERROR });
        toast.error("Activity Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_ACTIVITIES.ERROR });
      toast.error("Activity Adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getActivitiesDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_ACTIVITIES_DETAIL.LOADING });
      res = await api(`${APIS.activities}/${id}`);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_ACTIVITIES_DETAIL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_ACTIVITIES_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_ACTIVITIES_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editActivitiesAction(id, formData, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_ACTIVITIES.LOADING });

      res = await api(
        `${APIS.activities}/${id}`,
        "PATCH",
        formData,
        "formdata"
      );

      const { success, data } = res;

      if (success) {
        toast.success("Activity Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_ACTIVITIES.SUCCESS, payload: data });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_ACTIVITIES.ERROR });
        toast.error("Activity Editing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_ACTIVITIES.ERROR });
      toast.error("Activity Editing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteActivitiesAction(id, closeModal, type) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_ACTIVITIES.LOADING });
      res = await api(`${APIS.activities}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Activity deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_ACTIVITIES.SUCCESS, payload: data });
        closeModal();
        dispatch(getActivitiesAction(type));
      } else {
        dispatch({ type: DELETE_ACTIVITIES.ERROR });
        toast.error("Activity Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_ACTIVITIES.ERROR });
      toast.error("Activity Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
