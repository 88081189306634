import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import { TextField, Button, MenuItem } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addVacancyAction,
  getVacancyDetailAction,
  editVacancyAction,
} from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVacancyDetailAction,
      editVacancyAction,
    },
    dispatch
  );
};

const EditVacancyComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => {
  const { vacancy, editVacancyAction } = props;
  const { editLoader, detail } = vacancy;

  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();

  const blocksFromHtml = htmlToDraft(detail.description);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  const onChange = (editorState) => {
    setEditorState(editorState);
    setValue("editor", editorState);
  };

  const onSubmit = (data) => {
    let body = {
      title: data.title,
      description: `${draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )}`,
    };

    editVacancyAction(detail?.id, body, handleRedirection);
  };

  const handleRedirection = () => {
    navigate(routes["Vacancy"].path);
  };

  return (
    <div className="addblog-container">
      <div className="addblog">
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
        >
          <TextField
            name="title"
            inputRef={register({ required: true })}
            variant="outlined"
            label="Title"
            defaultValue={detail.title}
          />

          <Editor
            name="editor"
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onChange}
          />

          <ActivityIndicator animating={editLoader}>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </ActivityIndicator>
        </form>
      </div>
    </div>
  );
});

const EditVacancyPage = (props) => {
  const { params } = useNavigation();
  const { getVacancyDetailAction, vacancy } = props;
  const { detail } = vacancy;
  useEffect(() => {
    getVacancyDetailAction(Number(params.id));
  }, [params.id]);
  return (
    <CompWrapper>
      <Header title="edit Vacancy" />
      {!!detail ? <EditVacancyComponent /> : <div>Edit</div>}
    </CompWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditVacancyPage);
