// CREATE ROLES FOR USER

// SET ACCESS ROUTES FOR ALL ROLES
export const USER_ROLES = {
  admin: {
    access: [
      "/",
      "/log-in",
      "/sign-in",
      "/category",
      "/product",
      "/home",
      "/blog",
      "/blog/add",
      "/blog/edit/:id",
      "/gallary",
      "/gallary/add",
      "/gallary/edit/:id",
      "/our-leaders/*",
      "/testimonial",
      "/testimonial/add",
      "/testimonial/edit/:id",
      "/activities/*",
      "/activities/add",
      "/activities/edit/:id",
      "/publications",
      "/publications/add",
      "/publications/edit/:id",
      "/contact",
      "/user",
      "/vacancy/*",
      "/announcement",
      "/grant",
    ],
  },
  user: {
    access: ["/", "/log-in", "/sign-in"],
  },
};
