import React, { useEffect, useState } from "react";
import { useNavigation } from "react-uicomp";

import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { TextField, Button, MenuItem } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getLeaderDetailAction,
  editLeaderAction,
} from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

const TYPES = [
  {
    value: "researcher",
    label: "Researcher",
  },
  {
    value: "grantee",
    label: "Grantee",
  },
  {
    value: "leader",
    label: "Leader",
  },
];

const EditLeaderPage = (props) => {
  const { navigation, params } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const { getLeaderDetailAction, leader, editLeaderAction } = props;
  const { getLeaderDetailLoader, leaderDetail, editLeaderLoader } = leader;
  const [image, setImage] = useState();
  const [types, setTypes] = useState("researcher");

  useEffect(() => {
    getLeaderDetailAction(params.id);
  }, []);
  console.log("leaderleader", leader);

  useEffect(() => {
    if (leaderDetail?.data?.id) {
      setTypes(leaderDetail?.data?.type);
    }
  }, [leaderDetail?.data?.id]);

  const handleChange = (event) => {
    setTypes(event.target.value);
  };

  const onSubmit = async (data) => {
    if (types.length === 0) return;
    let formData = new FormData();
    if (!!image) {
      await formData.append("images", image);
    }
    await formData.append("name", data.name);
    await formData.append("position", data.position);
    await formData.append("bio", data.bio);
    await formData.append("email", data.email);
    await formData.append("linkedin", data.linkedin);
    await formData.append("type", types);

    editLeaderAction(params.id, formData, handleRedirection);
  };

  const handleRedirection = () => {
    navigate(routes["Leaders"].path);
  };

  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };

  console.log("type", types);
  return (
    <CompWrapper>
      <Header title="Edit Leader" />
      {leaderDetail && !getLeaderDetailLoader ? (
        <div className="addblog-container">
          <div className="addblog">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: 10,
              }}
            >
              <TextField
                defaultValue={leaderDetail.data.name}
                name="name"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Name"
              />
              <TextField
                defaultValue={leaderDetail.data.position}
                name="position"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Position"
              />

              <TextField
                defaultValue={leaderDetail.data.bio}
                name="bio"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Bio"
                multiline
                rows={4}
              />

              <TextField
                defaultValue={leaderDetail.data.email}
                name="email"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Email"
              />

              <TextField
                defaultValue={leaderDetail.data.linkedin}
                name="linkedin"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Linked In"
              />

              <TextField
                id="standard-select-types"
                select
                label="Select Types"
                value={types}
                onChange={handleChange}
                variant="outlined"
              >
                {TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <ImageUploading
                multiple={false}
                onChange={onChangeImage}
                defaultValue={
                  leaderDetail.data.images
                    ? [{ dataURL: leaderDetail.data.images[0] }]
                    : null
                }
              >
                {({ imageList, onImageUpload }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        marginTop: 10,
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}
                    >
                      {imageList.map((image) => (
                        <div key={image.key}>
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      onClick={onImageUpload}
                      variant="contained"
                      color="secondary"
                    >
                      Change Image
                    </Button>
                  </div>
                )}
              </ImageUploading>

              <ActivityIndicator animating={editLeaderLoader}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
      ) : (
        <div>loading...</div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    leader: state.leader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLeaderDetailAction,
      editLeaderAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLeaderPage);
