import React, { useState } from "react";
import { Auth, useNavigation } from "react-uicomp";
import { Button, ButtonGroup } from "@material-ui/core";

import { InvertedButton } from "../common/button/Button.common";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";

const ActivitiesListPage = () => {
  const { navigation, params, location } = useNavigation();
  const { navigate, routes } = navigation;
  const [active, setActive] = useState(
    location?.pathname.slice(12) || "training"
  );

  console.log("active", active);
  const tabBar = (value) => {
    setActive(value);
    console.log("value", value);
    navigate(`/activities/${value}`);
  };

  return (
    <CompWrapper>
      <Header title="Activities" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            variant={active === "training" ? "contained" : "outlined"}
            onClick={() => tabBar("training")}
          >
            {" "}
            Training
          </Button>
          <Button
            variant={active === "grant" ? "contained" : "outlined"}
            onClick={() => tabBar("grant")}
          >
            Grant
          </Button>
          <Button
            variant={active === "research" ? "contained" : "outlined"}
            onClick={() => tabBar("research")}
          >
            Research{" "}
          </Button>
          <Button
            variant={active === "consultancy" ? "contained" : "outlined"}
            onClick={() => tabBar("consultancy")}
          >
            Consultancy{" "}
          </Button>
        </ButtonGroup>

        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(routes.AddActivities.path)}
        >
          add Activities
        </Button>
      </div>
      <Auth.Screens path="/activities" />
    </CompWrapper>
  );
};

export default ActivitiesListPage;
