import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useForm, Controller } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import ImageUploading from "react-images-uploading";
import { TextField, Button } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addBlogAction } from "../../actions/Actions";

import CompWrapper from "../hocs/CompWrapper.hoc";
import Header from "../common/header/Header.common";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";

const AddBlogPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { addBlogAction, blog } = props;
  const { addBlogLoader } = blog;
  const { handleSubmit, register, setValue, control } = useForm();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);
  const onChange = (editorState) => {
    setEditorState(editorState);
    setValue("editor", editorState);
  };
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("images", image);
    let body = {
      title: data.title,
      description: `${draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )}`,
    };

    if (image) {
      addBlogAction(body, formData, handleRedirection);
    } else {
      setImageError(true);
    }
  };

  const handleRedirection = () => {
    navigate(routes["Blog"].path);
  };

  useEffect(() => {
    register("editor");
  }, [register]);

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  return (
    <CompWrapper>
      <Header title="Add Blog" />
      <div className="addblog-container">
        <div className="addblog">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
          >
            <TextField
              name="title"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Title"
            />
            <Editor
              name="editor"
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onChange}
            />

            <ImageUploading multiple={false} onChange={onChangeImage}>
              {({ imageList, onImageUpload }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}
                  >
                    {imageList.map((image) => (
                      <div
                        key={image.key}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                        }}
                      >
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={onImageUpload}
                    variant="contained"
                    color="secondary"
                  >
                    Upload Image
                  </Button>
                </div>
              )}
            </ImageUploading>

            {imageError && (
              <div style={{ color: "red" }}>
                <strong>Image is Required!!</strong>
              </div>
            )}
            <ActivityIndicator animating={addBlogLoader}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addBlogAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBlogPage);
