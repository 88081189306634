import React from "react";
import { Dropdown, useAuth } from "react-uicomp";
import { MdMenu, BsCaretDownFill } from "react-icons/all";
import {
  DropdownMenu,
  DropdownMenuItem,
  // DropdownMenuSeparator,
} from "../dropdown/Dropdown.common";
const Header = ({ title }) => {
  const { setSideNavVisible, handleLogout, userRole } = useAuth();
  return (
    <div className="header-container">
      <div className="header">
        <div className="header-menu" onClick={() => setSideNavVisible(true)}>
          <MdMenu />
        </div>
        <div className="header-left">{title}</div>

        <div className="header-right">
          <Dropdown
            placement="bottomright"
            triggerElement={({ active }) => (
              <div
                className={
                  active
                    ? "header-right-profile active"
                    : "header-right-profile"
                }
              >
                <div
                  style={{
                    // backgroundImage: `url(${AvatarImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  className="profile-image"
                />
                <div className="profile-name">
                  <span>{userRole}</span>
                  <BsCaretDownFill />
                </div>
              </div>
            )}
          >
            <DropdownMenu>
              <DropdownMenuItem danger onClick={handleLogout}>
                Logout
              </DropdownMenuItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
