import React, { useEffect ,useState} from "react";
import { useNavigation } from "react-uicomp";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPublicationDetailAction,editPublicationAction } from "../../../actions/Actions";
import CompWrapper from "../../hocs/CompWrapper.hoc";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import Header from "../../common/header/Header.common";
import DefaultButton from "../../common/button/Button.common";

const EditPublicationPage = (props) => {
  const { params,navigation } = useNavigation();
  const { navigate, routes } = navigation;

  const { getPublicationDetailAction , publication ,editPublicationAction} = props;
  const { getPublicationDetailLoader,editPublicationLoader, publicationDetail } = publication;

  const { handleSubmit, register, setValue, control } = useForm();
  const [image, setImage] = useState();
  const [file, setFile] = useState()
  
  useEffect(()=>{
      getPublicationDetailAction(params.id)
  },[])

  const onSubmit = async(data) => {
    let formData = new FormData();
    if(!!image){
      await formData.append("images", image);
    }
    if (file !== undefined && file[0] !== undefined) {
      formData.append("pdf", file[0]);
    }
    await formData.append("title", data.title);
    await formData.append("description", data.description);

    editPublicationAction(params.id, formData, handleRedirection);

  };

  const handleRedirection = () => {
    navigate(routes["Publications"].path);
  };


  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };

  const onChangeFile = (event) => {
    setFile([event.target.files[0]]);
}

  return (
    <CompWrapper>
      <Header title="Edit publication" />
      {publicationDetail&&!getPublicationDetailLoader?
      <div className="addblog-container">
      <div className="addblog">
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
        >
          <TextField
          defaultValue={publicationDetail.data.title}
            name="title"
            inputRef={register({ required: true })}
            variant="outlined"
            label="Title"
          />
                      <TextField
                                defaultValue={publicationDetail.data.description}
              name="description"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Description"
            />


          <ImageUploading multiple={false} onChange={onChangeImage} defaultValue={publicationDetail.data.images?[{ "dataURL": publicationDetail.data.images[0] }] :null} >
            {({ imageList, onImageUpload }) => (
              <div className="upload__image-wrapper">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat( auto-fill, minmax(200px, 1fr) )",
                    columnGap: 10,
                  }}
                >
                  {imageList.map((image) => (
                    <div
                      key={image.key}
                      style={{
                        position: "relative",
                        width: 200,
                        height: 200,
                      }}
                    >
                      <img
                        src={image.dataURL}
                        alt=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                  ))}
                </div>
                <Button
                  onClick={onImageUpload}
                  variant="contained"
                  color="secondary"
                >
                  Change Image
                </Button>
              </div>
            )}
          </ImageUploading>

              <div style={{ position: "relative", display: "inline-block", overflow: "hidden", padding: 10 }}>
                <input style={{ position: "absolute", left: 0, top: 0, opacity: 0, fontSize: 50, }} type="file" multiple name="thumbnail" onChange={(event) => { onChangeFile(event) }} />
                <DefaultButton onClick={() => false} title="Change Pdf File" />
              </div>
              {file &&
                <div className="text-rumpel">
                  {file[0]?.name}
                </div>
              }

          <ActivityIndicator animating={editPublicationLoader}>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </ActivityIndicator>
        </form>
      </div>
    </div>
    :<div>loading...</div>}
      </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    publication: state.publication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPublicationDetailAction,editPublicationAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPublicationPage);
