import React, { useState } from "react";
import { Auth, useNavigation } from "react-uicomp";
import { Button, ButtonGroup } from "@material-ui/core";

import { InvertedButton } from "../common/button/Button.common";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";

const LeaderListPage = () => {
  const { navigation, params, location } = useNavigation();
  const { navigate, routes } = navigation;
  const [active, setActive] = useState(
    location?.pathname.slice(13) || "leaders"
  );

  console.log("active", active);
  const tabBar = (value) => {
    setActive(value);
    console.log("value", value);
    navigate(`/our-leaders/${value}`);
  };

  return (
    <CompWrapper>
      <Header title="leaders" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            variant={active === "leaders" ? "contained" : "outlined"}
            onClick={() => tabBar("leaders")}
          >
            {" "}
            leader
          </Button>
          <Button
            variant={active === "grantee" ? "contained" : "outlined"}
            onClick={() => tabBar("grantee")}
          >
            Grantee
          </Button>
          <Button
            variant={active === "researcher" ? "contained" : "outlined"}
            onClick={() => tabBar("researcher")}
          >
            Researcher{" "}
          </Button>
        </ButtonGroup>

        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(routes.AddLeader.path)}
        >
          add Member
        </Button>
      </div>
      <Auth.Screens path="/our-leaders" />
    </CompWrapper>
  );
};

export default LeaderListPage;
