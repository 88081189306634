import { GET_TESTIMONIAL, ADD_TESTIMONIAL, GET_TESTIMONIAL_DETAIL, EDIT_TESTIMONIAL, DELETE_TESTIMONIAL } from "../actions/Actions";

const initalState = {
  getTestimonialLoader: false,
  addTestimonialLoader: false,
  getTestimonialDetailLoader:false,
  editTestimonialLoader: false,
  deleteTestimonialLoader: false,
  testimonialList: [],
  testimonialDetail:null
};

export function testimonialReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TESTIMONIAL.LOADING:
      return { ...state, getTestimonialLoader: true };
    case GET_TESTIMONIAL.SUCCESS:
      return { ...state, getTestimonialLoader: false, testimonialList: payload };
    case GET_TESTIMONIAL.ERROR:
      return { ...state, getTestimonialLoader: false };

    case ADD_TESTIMONIAL.LOADING:
      return { ...state, addTestimonialLoader: true, addTestimonialSuccess: false };
    case ADD_TESTIMONIAL.SUCCESS:
      return { ...state, addTestimonialLoader: false, addTestimonialSuccess: true };
    case ADD_TESTIMONIAL.ERROR:
      return { ...state, addTestimonialLoader: false, addTestimonialSuccess: false };

    case GET_TESTIMONIAL_DETAIL.LOADING:
      return { ...state, getTestimonialDetailLoader: true };
    case GET_TESTIMONIAL_DETAIL.SUCCESS:
      return { ...state, getTestimonialDetailLoader: false ,testimonialDetail:payload};
    case GET_TESTIMONIAL_DETAIL.ERROR:
      return { ...state, getTestimonialDetailLoader: false };

    case EDIT_TESTIMONIAL.LOADING:
      return { ...state, editTestimonialLoader: true };
    case EDIT_TESTIMONIAL.SUCCESS:
      return { ...state, editTestimonialLoader: false };
    case EDIT_TESTIMONIAL.ERROR:
      return { ...state, editTestimonialLoader: false };

    case DELETE_TESTIMONIAL.LOADING:
      return { ...state, deleteTestimonialLoader: true };
    case DELETE_TESTIMONIAL.SUCCESS:
      return { ...state, deleteTestimonialLoader: false };
    case DELETE_TESTIMONIAL.ERROR:
      return { ...state, deleteTestimonialLoader: false };

      default:
      return state;
  }
}
