import { toast } from "react-toastify";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import {
  ADD_VACANCY,
  DELETE_VACANCY,
  EDIT_VACANCY,
  GET_VACANCY,
  GET_VACANCY_DETAIL,
  GET_VACANCY_APPLICATION,
} from "./ActionTypes.action";

export function getVacancyAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_VACANCY.LOADING });
      res = await api(APIS.vacancy);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_VACANCY.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_VACANCY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_VACANCY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function getVacancyDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_VACANCY_DETAIL.LOADING });
      res = await api(`${APIS.vacancy}/${id}`);
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_VACANCY_DETAIL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_VACANCY_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_VACANCY_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addVacancyAction(body, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_VACANCY.LOADING });
      res = await api(APIS.vacancy, "POST", body);

      const { success } = res;

      if (success) {
        toast.success("Vacancy Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: ADD_VACANCY.SUCCESS });
        callback();
      } else {
        toast.error("Vacancy Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: ADD_VACANCY.ERROR });
      }
    } catch ({ message }) {
      toast.error("Vacancy Adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      dispatch({ type: ADD_VACANCY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editVacancyAction(id, body, callback) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_VACANCY.LOADING });
      res = await api(`${APIS.vacancy}/${id}`, "PATCH", body);

      const { success } = res;

      if (success) {
        toast.success("Vacancy Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_VACANCY.SUCCESS });
        callback();
      } else {
        toast.error("Vacancy Editing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_VACANCY.ERROR });
      }
    } catch ({ message }) {
      toast.error("Vacancy Editing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      dispatch({ type: EDIT_VACANCY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function deleteVacancyAction(id, closeModal) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_VACANCY.LOADING });
      res = await api(`${APIS.vacancy}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Vacancy Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_VACANCY.SUCCESS, payload: data });
        closeModal();
        dispatch(getVacancyAction());
      } else {
        dispatch({ type: DELETE_VACANCY.ERROR });
        toast.error("Vacancy Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_VACANCY.ERROR });
      toast.error("Vacancy Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getVacancyApplicationAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_VACANCY_APPLICATION.LOADING });
      res = await api(APIS.vacancy + "/apply");
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_VACANCY_APPLICATION.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_VACANCY_APPLICATION.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_VACANCY_APPLICATION.ERROR });
      console.error(message);
      return 0;
    }
  };
}
