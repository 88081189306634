import {
  GET_GALLARY,
  ADD_GALLARY,
  GET_GALLARY_DETAIL,
  EDIT_GALLARY,
  DELETE_GALLARY,
} from "./ActionTypes.action";
import { APIS } from "../config/Config";
import { api } from "../helpers/Helpers";
import { toast } from "react-toastify";

export function getGallaryAction() {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_GALLARY.LOADING });
      res = await api(`${APIS.gallary}/all`);
      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_GALLARY.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_GALLARY.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_GALLARY.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function addGallaryAction(body, formdata, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: ADD_GALLARY.LOADING });

      res = await api(`${APIS.gallary}`, "POST", body);

      const { success, data } = res;

      if (success) {
        let response = await api(
          `${APIS.gallary}/${data.data.id}/addimage`,
          "PATCH",
          formdata,
          "formdata"
        );
        if (response.success === "true") {
          toast.success("Gallery Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
          dispatch({ type: ADD_GALLARY.SUCCESS, payload: data });
          handleRedirection();
        } else {
          dispatch({ type: ADD_GALLARY.ERROR });
          toast.error("Gallery Adding Failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        }
      } else {
        dispatch({ type: ADD_GALLARY.ERROR });
        toast.error("Gallery Adding Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: ADD_GALLARY.ERROR });
      toast.error("Gallery Adding Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function getGallaryDetailAction(id) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: GET_GALLARY_DETAIL.LOADING });
      res = await api(`${APIS.gallary}/${id}`);

      const { success, data } = res;

      if (success) {
        dispatch({ type: GET_GALLARY_DETAIL.SUCCESS, payload: data });
      } else {
        dispatch({ type: GET_GALLARY_DETAIL.ERROR });
      }
    } catch ({ message }) {
      dispatch({ type: GET_GALLARY_DETAIL.ERROR });
      console.error(message);
      return 0;
    }
  };
}

export function editGallaryAction(id, formData, handleRedirection) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: EDIT_GALLARY.LOADING });

      res = await api(`${APIS.gallary}/${id}`, "PATCH", formData, "formdata");

      const { success, data } = res;

      if (success) {
        toast.success("Gallery Edited Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: EDIT_GALLARY.SUCCESS, payload: data });
        handleRedirection();
      } else {
        dispatch({ type: EDIT_GALLARY.ERROR });
        toast.error("Gallery Editing Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: EDIT_GALLARY.ERROR });
      toast.error("Gallery Editing Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}

export function deleteGallaryAction(id, closeModal) {
  return async function (dispatch) {
    let res;
    try {
      dispatch({ type: DELETE_GALLARY.LOADING });
      res = await api(`${APIS.gallary}/${id}`, "DELETE");
      const { success, data } = res;
      if (success === "true") {
        toast.success("Gallery Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        dispatch({ type: DELETE_GALLARY.SUCCESS, payload: data });
        closeModal();
        dispatch(getGallaryAction());
      } else {
        dispatch({ type: DELETE_GALLARY.ERROR });
        toast.error("Gallery Deleting Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch ({ message }) {
      dispatch({ type: DELETE_GALLARY.ERROR });
      toast.error("Gallery Deleting Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      console.error(message);
      return 0;
    }
  };
}
