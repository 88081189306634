import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      404 PAGE NOT FOUND!!!
    </div>
  )
}

export default NotFoundPage;