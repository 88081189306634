import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { TextField, Button, MenuItem } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getActivitiesDetailAction,
  editActivitiesAction,
} from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";
import DefaultButton from "../../common/button/Button.common";

const TYPES = [
  {
    value: "training",
    label: "Training",
  },
  {
    value: "grant",
    label: "Grant",
  },
  {
    value: "research",
    label: "Research",
  },
  {
    value: "consultancy",
    label: "Consultancy",
  },
];

const EditActivitiesPage = (props) => {
  const { navigation, params } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register } = useForm();
  const { getActivitiesDetailAction, activities, editActivitiesAction } = props;
  const {
    getActivitiesDetailLoader,
    activitiesDetail,
    editActivitiesLoader,
  } = activities;
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [types, setTypes] = useState("");

  console.log("activitiesDetail", activitiesDetail);
  useEffect(() => {
    if (activitiesDetail?.data?.id) {
      setTypes(activitiesDetail?.data?.type);
    }
  }, [activitiesDetail?.data?.id]);

  useEffect(() => {
    getActivitiesDetailAction(params.id);
  }, []);

  const onSubmit = async (data) => {
    let formData = new FormData();
    if (!!image) {
      await formData.append("images", image);
    }
    if (file !== undefined && file[0] !== undefined) {
      formData.append("pdf", file[0]);
    }

    await formData.append("title", data.title);
    await formData.append("description", data.description);
    await formData.append("type", types);

    editActivitiesAction(params.id, formData, handleRedirection);
  };

  const handleRedirection = () => {
    navigate(routes["Activities"].path);
  };

  const onChangeImage = (imageList) => {
    setImage(imageList[0]?.file);
  };

  const handleChange = (event) => {
    setTypes(event.target.value);
  };

  const onChangeFile = (event) => {
    setFile([event.target.files[0]]);
  };

  return (
    <CompWrapper>
      <Header title="Edit Activities" />
      {activitiesDetail && !getActivitiesDetailLoader ? (
        <div className="addblog-container">
          <div className="addblog">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr",
                rowGap: 10,
              }}
            >
              <TextField
                defaultValue={activitiesDetail.data.title}
                name="title"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Title"
              />
              <TextField
                defaultValue={activitiesDetail.data.description}
                name="description"
                inputRef={register({ required: true })}
                variant="outlined"
                label="Description"
                multiline
                rows={4}
              />

              <TextField
                id="standard-select-types"
                select
                label="Select Types"
                value={types}
                onChange={handleChange}
                variant="outlined"
              >
                {TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <ImageUploading
                multiple={false}
                onChange={onChangeImage}
                defaultValue={
                  activitiesDetail.data.images
                    ? [{ dataURL: activitiesDetail.data.images[0] }]
                    : null
                }
              >
                {({ imageList, onImageUpload }) => (
                  <div className="upload__image-wrapper">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat( auto-fill, minmax(200px, 1fr) )",
                        columnGap: 10,
                      }}
                    >
                      {imageList.map((image) => (
                        <div
                          key={image.key}
                          style={{
                            position: "relative",
                            width: 200,
                            height: 200,
                          }}
                        >
                          <img
                            src={image.dataURL}
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Button
                      onClick={onImageUpload}
                      variant="contained"
                      color="secondary"
                    >
                      Change Image
                    </Button>
                  </div>
                )}
              </ImageUploading>

              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  overflow: "hidden",
                  padding: 10,
                }}
              >
                <input
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    opacity: 0,
                    fontSize: 50,
                  }}
                  type="file"
                  multiple
                  name="thumbnail"
                  onChange={(event) => {
                    onChangeFile(event);
                  }}
                />
                <DefaultButton onClick={() => false} title="Change Pdf File" />
              </div>
              {file && <div className="text-rumpel">{file[0]?.name}</div>}

              <ActivityIndicator animating={editActivitiesLoader}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </ActivityIndicator>
            </form>
          </div>
        </div>
      ) : (
        <div>loading...</div>
      )}
    </CompWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActivitiesDetailAction,
      editActivitiesAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditActivitiesPage);
