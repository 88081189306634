import { ADD_BLOG, GET_BLOG, GET_BLOG_DETAIL, EDIT_BLOG, DELETE_BLOG } from "../actions/Actions";

const initalState = {
  getBlogLoader: false,
  addBlogLoader: false,
  getBlogDetailLoader:false,
  editBlogLoader: false,
  deleteBlogLoader: false,
  blogList: [],
  blogDetail:null
};

export function blogReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BLOG.LOADING:
      return { ...state, getBlogLoader: true };
    case GET_BLOG.SUCCESS:
      return { ...state, getBlogLoader: false, blogList: payload };
    case GET_BLOG.ERROR:
      return { ...state, getBlogLoader: false };

    case ADD_BLOG.LOADING:
      return { ...state, addBlogLoader: true, addBlogSuccess: false };
    case ADD_BLOG.SUCCESS:
      return { ...state, addBlogLoader: false, addBlogSuccess: true };
    case ADD_BLOG.ERROR:
      return { ...state, addBlogLoader: false, addBlogSuccess: false };

    case GET_BLOG_DETAIL.LOADING:
      return { ...state, getBlogDetailLoader: true };
    case GET_BLOG_DETAIL.SUCCESS:
      return { ...state, getBlogDetailLoader: false ,blogDetail:payload};
    case GET_BLOG_DETAIL.ERROR:
      return { ...state, getBlogDetailLoader: false };

    case EDIT_BLOG.LOADING:
      return { ...state, editBlogLoader: true };
    case EDIT_BLOG.SUCCESS:
      return { ...state, editBlogLoader: false };
    case EDIT_BLOG.ERROR:
      return { ...state, editBlogLoader: false };

    case DELETE_BLOG.LOADING:
      return { ...state, deleteBlogLoader: true };
    case DELETE_BLOG.SUCCESS:
      return { ...state, deleteBlogLoader: false };
    case DELETE_BLOG.ERROR:
      return { ...state, deleteBlogLoader: false };

      default:
      return state;
  }
}
