import React, { useState, useEffect } from "react";
import { useNavigation, Modal } from "react-uicomp"
import { Button, Paper } from "@material-ui/core"
import MaterialTable from "material-table"

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPublicationAction, deletePublicationAction,addPublicationAction } from "../../actions/Actions";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import Header from "../common/header/Header.common";
import CompWrapper from "../hocs/CompWrapper.hoc";

const PublicationsPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { getPublicationAction, publication, deletePublicationAction } = props;
  const { publicationList, deletePublicationLoader } = publication;
  const [visible, setVisible] = useState(false);
  const [publicationData, setpublicationData] = useState();

  const toAddPage = () => {
    navigate(routes.AddPublication.path);
  };


  useEffect(() => {
    getPublicationAction();
  }, [getPublicationAction]);

  const deleteModal = (id) => {
    setVisible(true)
    setpublicationData(id)
  }

  const onDeleteHandler = () => {
    deletePublicationAction(publicationData, closeModal)
  }

  const closeModal = () => {
    setVisible(false)
  }


  return (
    <CompWrapper>
      <Header title="Publications" />
      <div>

        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => toAddPage()}
        >
          add Publicaiton
        </Button>

        <Modal visible={visible} onClose={() => setVisible(false)}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Are you sure you want to delete?
                  <ActivityIndicator animating={deletePublicationLoader}>
              <div style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}>

                <Button color="default" style={{ background: 'red', color: 'white' }} onClick={() => onDeleteHandler()}> Delete </Button>
                <Button color="secondary" onClick={() => setVisible(false)} >Cancel</Button>
              </div>
            </ActivityIndicator>
          </div>
        </Modal>
        <MaterialTable

          columns={[
            {
              title: "S.N.",
              field: "tableData.id",
              render: (rowData) => rowData.tableData.id + 1,
              width: 80,
            },
            {
              title: "Image",
              field: "images",
              render: (rowData) => (
                <img
                  alt="MediaImage"
                  style={{ height: 100, borderRadius: 4 }}
                  src={rowData.images && rowData.images[0]}
                />
              ),
            },
            { title: "Title", field: "title" },
            { title: "Description", field: "description" },
          ]}
          data={publicationList?.data}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                navigate(routes.Publications.path + "/edit/" + rowData.id)
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                deleteModal(rowData.id)
              },
            }),
          ]}

          options={{
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: false,
            toolbar: false,
            search: false,
            headerStyle: {
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
        />

      </div>
    </CompWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    publication: state.publication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPublicationAction, deletePublicationAction
    },
    dispatch
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(PublicationsPage);
