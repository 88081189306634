import {
  GET_LEADER,
  ADD_LEADER,
  GET_LEADER_DETAIL,
  EDIT_LEADER,
  DELETE_LEADER,
} from "../actions/Actions";

const initalState = {
  getLeaderLoader: false,
  addLeaderLoader: false,
  getLeaderDetailLoader: false,
  editLeaderLoader: false,
  deleteLeaderLoader: false,
  leaderList: [],
  leaderDetail: null,
};

export function leaderReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADER.LOADING:
      return { ...state, getLeaderLoader: true, leaderList: [] };
    case GET_LEADER.SUCCESS:
      return { ...state, getLeaderLoader: false, leaderList: payload };
    case GET_LEADER.ERROR:
      return { ...state, getLeaderLoader: false };

    case ADD_LEADER.LOADING:
      return { ...state, addLeaderLoader: true, addLeaderSuccess: false };
    case ADD_LEADER.SUCCESS:
      return { ...state, addLeaderLoader: false, addLeaderSuccess: true };
    case ADD_LEADER.ERROR:
      return { ...state, addLeaderLoader: false, addLeaderSuccess: false };

    case GET_LEADER_DETAIL.LOADING:
      return { ...state, getLeaderDetailLoader: true };
    case GET_LEADER_DETAIL.SUCCESS:
      return { ...state, getLeaderDetailLoader: false, leaderDetail: payload };
    case GET_LEADER_DETAIL.ERROR:
      return { ...state, getLeaderDetailLoader: false };

    case EDIT_LEADER.LOADING:
      return { ...state, editLeaderLoader: true };
    case EDIT_LEADER.SUCCESS:
      return { ...state, editLeaderLoader: false };
    case EDIT_LEADER.ERROR:
      return { ...state, editLeaderLoader: false };

    case DELETE_LEADER.LOADING:
      return { ...state, deleteLeaderLoader: true };
    case DELETE_LEADER.SUCCESS:
      return { ...state, deleteLeaderLoader: false };
    case DELETE_LEADER.ERROR:
      return { ...state, deleteLeaderLoader: false };

    default:
      return state;
  }
}
