import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TextField, Button, MenuItem } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addVacancyAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

const AddVacancyPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const { vacancy, addVacancyAction } = props;
  const { addLoader } = vacancy;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onSubmit = (data) => {
    let body = {
      title: data.title,
      description: `${draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )}`,
    };

    addVacancyAction(body, handleRedirection);
  };

  const handleRedirection = () => {
    navigate(routes["Vacancy"].path);
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
    setValue("editor", editorState);
  };

  return (
    <CompWrapper>
      <Header title="Add Vacancy" />
      <div className="addblog-container">
        <div className="addblog">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
          >
            <TextField
              name="title"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Title"
            />

            <Editor
              name="editor"
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onChange}
            />

            <ActivityIndicator animating={addLoader}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

// export default AddVacancyPage;
const mapStateToProps = (state) => {
  return {
    vacancy: state.vacancy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addVacancyAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVacancyPage);
