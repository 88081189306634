import React, { useState, useEffect } from "react";
import { useNavigation } from "react-uicomp";
import { useForm, Controller } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import ImageUploading from "react-images-uploading";
import { TextField, Button } from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addTestimonialAction } from "../../../actions/Actions";

import CompWrapper from "../../hocs/CompWrapper.hoc";
import Header from "../../common/header/Header.common";
import ActivityIndicator from "../../hocs/ActivityIndicator.hoc";

const AddTestimonialPage = (props) => {
  const { navigation } = useNavigation();
  const { navigate, routes } = navigation;
  const { handleSubmit, register, setValue, control } = useForm();
  const { addTestimonialAction, testimonial } = props;
  const { addTestimonialLoader } = testimonial;
  const [image, setImage] = useState();
  const [imageError, setImageError] = useState(false);

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("images", image);
    let body = {
      name: data.name,
      position: data.position,
      message: data.message,
    };

    if (image) {
      addTestimonialAction(body, formData, handleRedirection);
    } else {
      setImageError(true);
    }
  };

  const handleRedirection = () => {
    navigate(routes["Testimonial"].path);
  };

  const onChangeImage = (imageList) => {
    imageList[0]?.file && setImageError(false);
    setImage(imageList[0]?.file);
  };

  return (
    <CompWrapper>
      <Header title="Add Testimonial" />
      <div className="addblog-container">
        <div className="addblog">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "grid", gridTemplateColumns: "1fr", rowGap: 10 }}
          >
            <TextField
              name="name"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Name"
            />
            <TextField
              name="position"
              inputRef={register({ required: true })}
              variant="outlined"
              label="Position"
            />
            <TextField
              name="message"
              inputRef={register({ required: true })}
              variant="outlined"
              label="message"
            />

            <ImageUploading multiple={false} onChange={onChangeImage}>
              {({ imageList, onImageUpload }) => (
                <div className="upload__image-wrapper">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat( auto-fill, minmax(200px, 1fr) )",
                      columnGap: 10,
                    }}
                  >
                    {imageList.map((image) => (
                      <div
                        key={image.key}
                        style={{
                          position: "relative",
                          width: 200,
                          height: 200,
                        }}
                      >
                        <img
                          src={image.dataURL}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <Button
                    onClick={onImageUpload}
                    variant="contained"
                    color="secondary"
                  >
                    Upload Image
                  </Button>
                </div>
              )}
            </ImageUploading>

            {imageError && (
              <div style={{ color: "red" }}>
                <strong>Image is Required!!</strong>
              </div>
            )}
            <ActivityIndicator animating={addTestimonialLoader}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </ActivityIndicator>
          </form>
        </div>
      </div>
    </CompWrapper>
  );
};

// export default AddTestimonialPage;
const mapStateToProps = (state) => {
  return {
    testimonial: state.testimonial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTestimonialAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTestimonialPage);
