import { PlaylistAddOutlined } from "@material-ui/icons";
import { GRANT } from "../actions/Actions";

const initalState = {
  loading: false,
  error: false,
  grantList: [],
};

export function grantReducer(state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GRANT.LOADING:
      return { ...state, loading: true, error: false, grantList: [] };
    case GRANT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        grantList: payload.data,
      };
    case GRANT.ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
