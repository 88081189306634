import React from "react";
import { useAuth } from "react-uicomp";
import {useForm} from "react-hook-form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    loginAction
} from "../../actions/Actions";

import UsernameLogo from "../../assets/icons/Username.png";
import PasswordLogo from "../../assets/icons/Password.png";
import ActivityIndicator from "../hocs/ActivityIndicator.hoc";
import { InvertedButton } from "../common/button/Button.common"

const LoginPage = (props) => {
  const { loginAction,login} =props
  const {loginLoader}=login
  const { handleLogin } = useAuth()
  const { register ,handleSubmit}=useForm()
  const onLogin = async (data) => {
    
    let body={
      email:data.username,
      password:data.password
   }
   
    loginAction(body,handleLogin)

  }
  return (
    <div className="login-container">
      {/* <Header title="Login"/> */}
      <div className="login">
        <div className="login-title">
          MEMBER LOGIN
        </div>
        <form onSubmit={handleSubmit(onLogin)}>
          <div className="login-input">
            <div className="login-input-logo">
              <img src={UsernameLogo} alt="USERNAME" />
            </div>

            <input name="username" ref={register({required:true})} type="text" className="login-input-field" placeholder="Username" />
          </div>

          <div className="login-input">
            <div className="login-input-logo">
              <img src={PasswordLogo} alt="PASSWORD" />
            </div>

            <input name="password" ref={register({required:true})} type="password" className="login-input-field" placeholder="Password" />
          </div>

          <div className="login-submit">
            <ActivityIndicator  
            animating={loginLoader}
            style={{
              paddingBottom: 0,
            }}>
              <InvertedButton type="submit" title="LOGIN" style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }} onClick={()=>false}/>
            </ActivityIndicator>

          </div>
        </form>

      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {
        loginAction
      },
      dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
